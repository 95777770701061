@import "../../../../style/main.scss";

.desktop {
  display: none;
  height: 100%;

  @media only screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .popupBackgroundDelete {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    cursor: pointer;
  }

  .deletePopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 100%;
    height: auto;
    z-index: 6;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      top: 0;
    }

    .deleteHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 100%;

      img {
        position: absolute;
        right: 30px;
        top: 45px;
        cursor: pointer;
      }

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        width: 80%;
        margin-top: 40px;
        padding: 0;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $primary-color;
        width: 80%;
        margin: 40px 0 20px;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }

      .btns {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 60%;
        margin-top: 40px;

        .cancel {
          width: 50%;
          background: #ffffff;
          border: 1px solid rgba(0, 102, 255, 0.05);
          box-shadow: 0px 2px 15px rgb(4 0 21 / 10%);
          border-radius: 10px;
          padding: 10px 0;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: $secondary-color;
          cursor: pointer;
        }

        .delete {
          width: 50%;
          background: #0066ff;
          box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
          border-radius: 10px;
          border: none;
          padding: 10px 0;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .leftSide {
    width: 390px;
    background: #fafafa;
    border-right: 1px solid #e8e8ea;

    @media only screen and (min-width: 1700px) {
      width: 30%;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      margin-bottom: 40px;
      padding: 45px 20px 0px;

      .messagesSearch {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;


        input {
          width: 95%;
          padding: 13px 10px;
          border: 1px solid gray;
          border-radius: 5px;
          background: #ffffff;
          box-shadow: 0px 4px 84px rgb(0 0 0 / 7%);
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          outline: none;
        }

      }
    }

    .list {
      overflow-y: scroll;
      height: calc(100vh - 132px);

      .conversation {
        display: flex;
        align-items: end;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        padding: 20px;

        &:hover {
          background-color: #e1ebfb;
        }

        .info {
          display: flex;
          align-items: center;
          gap: 15px;

          .data {
            h3 {
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: $primary-color;
            }

            h5 {
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: $primary-color;
            }

            p {
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: $third-color;
              width: 15ch;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              @media only screen and (min-width: 1100px) {
                width: 20ch;
              }

              @media only screen and (min-width: 1200px) {
                width: 30ch;
              }

              @media only screen and (min-width: 1400px) {
                width: 40ch;
              }
            }

            .unread {
              font-weight: 700;
              color: #7f7d87;
            }
          }
        }

        .time {
          position: relative;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: $third-color;
        }

        .notification {
          position: absolute;
          top: 20px;
          right: 20px;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 10px;
          color: #fff;
          background: $secondary-color;
          border-radius: 4px;
          padding: 2px 4px;
        }
      }
    }
  }

  .rightSide {
    background-color: #fafafa;
    width: calc(100% - 390px);
    position: relative;

    .popupBackground {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 3;
      cursor: pointer;
    }

    .emailPopup {
      position: absolute;
      right: 20px;
      top: 50px !important;
      margin: auto;
      background: #fafafa;
      border: 1px solid #e8e8ea;
      box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
      transform: translate(0) !important;
      border-radius: 10px;
      width: fit-content;
      height: auto;
      z-index: 4;

      ul {
        background-color: #e2e2e2;
        border-radius: 4px;
        list-style-type: none;
        display: flex;
        flex-direction: column;

        li {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: $primary-color;
          cursor: pointer;
          padding: 12px 16px;

          &:hover {
            background: #b0afb5;
          }
        }
      }
    }

    .conversationHeader {
      padding: 24px 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e8e8ea;

      .leftInfo {
        display: flex;
        align-items: center;
        gap: 10px;

        h2 {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: $primary-color;
        }

        .tags {
          display: flex;
          align-items: center;
          gap: 6px;

          .match {
            background: #e1ebfb;
            border-radius: 4px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: $secondary-color;
            padding: 4px 6px;
            text-transform: uppercase;
          }

          .jobSeeking {
            background: #c4f1d9;
            border-radius: 4px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            color: #14c364;
            text-transform: uppercase;
            padding: 4px 6px;
            width: -moz-fit-content;
            width: fit-content;
          }

          .openToWork {
            color: #CE8D0F;
            background: #FFEEC0;
            border-radius: 4px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            padding: 4px 6px;
            width: -moz-fit-content;
            width: fit-content;
          }

          .networking {
            color: #10C9E2;
            background: #D7F3F6;
            border-radius: 4px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            padding: 4px 6px;
            width: -moz-fit-content;
            width: fit-content;
          }
        }
      }

      .rightInfo {
        img {
          cursor: pointer;
        }
      }
    }

    .messageBody {
      .messages {

        .left {
          margin-left: 24px;
          height: calc(100vh - 197px);
          overflow-y: scroll;
          display: flex;
          flex-direction: column-reverse;

          .loader {
            margin: auto;
          }

          .leftMessages {
            margin-bottom: 25px;
            width: fit-content;

            .content {
              background: #e8e8ea;
              width: fit-content;
              border-top-left-radius: 10px;
              border-top-left-radius: 10px;
              border-bottom-right-radius: 10px;
              padding: 16px;
              max-width: 400px;
              overflow-wrap: break-word;
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;

              @media only screen and (max-width: 1100px) {
                max-width: 340px;
              }
            }

            .time {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #7f7d87;
              text-align: right;
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: end;
          margin-right: 24px;
          margin-bottom: 15px;

          .empty {
            display: none;
          }

          .content {
            background: #e1ebfb;
            max-width: 400px;
            border-top-left-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 16px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $primary-color;
            font-size: 12px;
            overflow-wrap: break-word;

            @media only screen and (max-width: 1100px) {
              max-width: 340px;
            }
          }

          .time {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #7f7d87;
            text-align: right;
          }
        }
      }

      .sendMessage {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #e8e8ea;
        padding: 20px;

        .textMessage {
          position: relative;

          .messageForm {
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 1px solid #e8e8ea;
            box-shadow: 2px -1px 30px rgba(4, 0, 21, 0.05);
            border-radius: 100px;
            width: 98%;
            padding: 10px;
            justify-content: space-between;

            input,
            textarea {
              width: 100%;
              padding: 5px;
              border: none;
              outline: none;
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              resize: none;
              height: 20px;
  
              @media only screen and (max-width: 1200px) {
                width: 92%;
              }
  
              &::placeholder {
                color: #7f7d87;
              }
            }

            button {
              background: $secondary-color;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
          }

        }
      }
    }

    @media only screen and (min-width: 1700px) {
      width: 70%;
    }
  }

  .chouseConversation {
    width: 60%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: $primary-font;
    font-style: normal;

    line-height: 34px;
    color: $primary-color;
    @media only screen and (max-width: 1400px) {
      width: 70%;
    }
  }
}

.participant-image {
  width: 52px;
  height: 52px;
  border-radius: 10px;
}

.edit {
  cursor: pointer;
}

.jobPositionTitle {
  font-weight: 400;
  font-size: 12px;
}