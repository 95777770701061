@import "../../../../style/main.scss";

.mobile {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;

    .messagesSearch {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;


      input {
        width: 95%;
        padding: 13px 10px;
        border: 1px solid gray;
        border-radius: 5px;
        background: #ffffff;
        box-shadow: 0px 4px 84px rgb(0 0 0 / 7%);
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        outline: none;
      }

    }
  }

  .menuBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    // z-index: 3;
    cursor: pointer;
  }

  .mobileContentMenu {
    position: fixed;
    right: 0;
    top: 0;
    // padding: 20px;
    background: #fafafa;
    height: 100%;
    width: 100%;
    border-right: 1px solid #e3dede;
    z-index: 3;

    .mobileMenuHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border: 1px solid #e8e8ea;
      box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.05);

      .mobileTitle {
        display: flex;
        align-items: center;
        gap: 20px;

        h3 {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: $primary-color;
          margin-bottom: 5px;
        }

        .chatStats {
          display: flex;
          align-items: center;
          gap: 5px;

          .matching {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            color: #0066ff;
            background: rgba(0, 102, 255, 0.2);
            border-radius: 4px;
            text-transform: uppercase;
            padding: 4px;
            width: -moz-fit-content;
            width: fit-content;
          }

          .jobSeeking {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            color: #14c364;
            background: #c4f1d9;
            border-radius: 4px;
            text-transform: uppercase;
            padding: 4px;
            width: -moz-fit-content;
            width: fit-content;
          }

          .openToWork {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            color: #CE8D0F;
            background: #FFEEC0;
            border-radius: 4px;
            text-transform: uppercase;
            padding: 4px;
            width: -moz-fit-content;
            width: fit-content;
          }

          .networking {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            color: #10C9E2;
            background: #D7F3F6;
            border-radius: 4px;
            text-transform: uppercase;
            padding: 4px;
            width: -moz-fit-content;
            width: fit-content;
          }
        }
      }

      .closeMenu {
        svg {
          cursor: pointer;
        }
      }
    }

    .mobileMessages {
      height: calc(100% - 213px);
      overflow-y: scroll;
      .content {
        padding: 0 10px;
        overflow-y: scroll;
        height: 100%;
        display: flex;
        flex-direction: column-reverse;

        .loader {
          margin: auto;
        }

        .leftMessages {
          margin-bottom: 25px;
          width: fit-content;

          .content {
            background: #e8e8ea;
            width: fit-content;
            border-top-left-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 16px;
            max-width: 400px;

            @media only screen and (max-width: 1100px) {
              max-width: 340px;
            }

            @media only screen and (max-width: 540px) {
              max-width: 280px;
            }

            @media only screen and (max-width: 370px) {
              max-width: 200px;
            }

            &:last-child {
              margin-bottom: 100px;
            }

            p {
              overflow-wrap: break-word;
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: $primary-color;
              max-width: 400px;
              overflow-wrap: break-word;

              @media only screen and (max-width: 540px) {
                max-width: 280px;
              }

              @media only screen and (max-width: 370px) {
                max-width: 200px;
              }

              &.empty {
                display: none;
              }
            }
          }

          .time {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #7f7d87;
            text-align: right;
          }
        }

        // .leftChat {
        //   width: fit-content;
        //   margin-bottom: 20px;
        //   .message {
        //     background: #e1ebfb;
        //     padding: 16px;
        //     border-top-left-radius: 10px;
        //     border-top-right-radius: 10px;
        //     border-bottom-right-radius: 10px;
        //     font-family: $primary-font;
        //     font-style: normal;
        //     font-weight: 400;
        //     font-size: 12px;
        //     line-height: 18px;
        //     color: $primary-color;
        //   }

        //   .time {
        //     font-family: $primary-font;
        //     font-style: normal;
        //     font-weight: 400;
        //     font-size: 12px;
        //     line-height: 18px;
        //     color: $third-color;
        //     text-align: right;
        //   }
        // }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: end;
          margin-bottom: 15px;

          .empty {
            display: none;
          }

          .content {
            background: #e1ebfb;
            width: fit-content;
            max-width: 400px;
            border-top-left-radius: 10px;
            border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 16px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $primary-color;

            @media only screen and (max-width: 540px) {
              max-width: 280px;
            }

            @media only screen and (max-width: 370px) {
              max-width: 200px;
            }

            p {
              overflow-wrap: break-word;
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }
          }
          .time {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #7f7d87;
            text-align: right;
          }
        }

        .rightChat {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: end;
          width: 100%;

          .message {
            background: #e8e8ea;
            padding: 16px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 10px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $primary-color;
          }

          .time {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $third-color;
            text-align: right;
          }
        }

        .enterMessage {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #e8e8ea;
          padding: 20px 20px 40px 20px;

          .textMessage {
            position: relative;

            .messageForm {
              display: flex;
              align-items: center;
              background: #ffffff;
              border: 1px solid #e8e8ea;
              box-shadow: 2px -1px 30px rgba(4, 0, 21, 0.05);
              border-radius: 100px;
              width: 98%;
              padding: 10px;
              justify-content: space-between;
  
              input,
              textarea {
                width: 100%;
                padding: 5px;
                border: none;
                outline: none;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                resize: none;
                height: 20px;
    
                @media only screen and (max-width: 1200px) {
                  width: 92%;
                }
    
                &::placeholder {
                  color: #7f7d87;
                }
              }
  
              button {
                background: $secondary-color;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .popupBackground {
    background: transparent;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    cursor: pointer;
  }

  .emailPopup {
    position: absolute;
    right: 20px;
    top: 50px !important;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: fit-content;
    height: auto;
    z-index: 6;

    ul {
      background-color: #e2e2e2;
      border-radius: 4px;
      list-style-type: none;
      display: flex;
      flex-direction: column;

      li {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $primary-color;
        cursor: pointer;
        padding: 12px 16px;

        &:hover {
          background: #b0afb5;
        }
      }
    }
  }

  .popupBackgroundDelete {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    cursor: pointer;
  }

  .deletePopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 100%;
    height: auto;
    z-index: 6;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      top: 0;
    }

    .deleteHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      height: 100%;

      img {
        position: absolute;
        right: 30px;
        top: 45px;
      }

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        width: 80%;
        margin-top: 40px;
        padding: 0;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $primary-color;
        width: 80%;
        margin: 40px 0 20px;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }

      .btns {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 60%;
        margin-top: 40px;

        .cancel {
          width: 50%;
          background: #ffffff;
          border: 1px solid rgba(0, 102, 255, 0.05);
          box-shadow: 0px 2px 15px rgb(4 0 21 / 10%);
          border-radius: 10px;
          padding: 10px 0;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: $secondary-color;
          cursor: pointer;
        }

        .delete {
          width: 50%;
          background: #0066ff;
          box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
          border-radius: 10px;
          border: none;
          padding: 10px 0;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .list {
    height: 67vh;
    overflow-y: scroll;
    padding: 0 20px;

    @media only screen and (max-width: 800px) {
      height: 70vh;
    }

    @media only screen and (max-width: 700px) {
      height: 72vh;
    }

    @media only screen and (max-width: 500px) {
      height: 75vh;
    }

    @media only screen and (max-width: 400px) {
      height: 77vh;
    }

    .conversation {
      display: flex;
      align-items: end;
      justify-content: space-between;
      margin-bottom: 20px;
      position: relative;

      .info {
        display: flex;
        align-items: center;
        gap: 15px;

        .data {
          h3 {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $primary-color;
          }

          h5 {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $primary-color;
          }

          p {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $third-color;
            width: 30ch;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .time {
        position: relative;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $third-color;
      }

      .notification {
        position: absolute;
        top: 0;
        right: 0;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 10px;
        color: #fff;
        background: $secondary-color;
        border-radius: 4px;
        padding: 2px 4px;
      }
    }
  }

  .textareaField {
    overflow: hidden;
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
}


.participant-image {
  width: 42px;
  height: 42px;
  border-radius: 10px;
}