.completeProfile {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 992px) {
        height: 85vh;
    }

    @media only screen and (max-width: 768px) {
        height: 90vh;
    }

    .completeProfileImg {
        margin-bottom: 40px;
    }

    .completeProfileTitle {
        font-size: 24px;
        color: #040015;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;

        @media only screen and (max-width: 768px) {
            font-size: 20px;
        }
    }

    .completeProfileParagraph {
        font-size: 16px;
        color: #7F7D87;
        margin-bottom: 40px;
        text-align: center;
        margin: 0 10px 40px 10px;
    }

    .goToBtn {
        width: 344px;
        border-radius: 10px;
        padding: 10px;
        background-color: #0066FF;
        color: #fff;
        border: 0;
        font-weight: 600;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
            width: 250px;
        }
    }
}