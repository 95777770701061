@import "../../style/main.scss";

.forgotPassword {
  .forgotPasswordContent {
    // padding-bottom: 40px;
    h2 {
      width: 85%;
      margin: auto;
      margin-top: -25px;
    }

    .MuiFormControl-root {
      margin-bottom: 20px;
    }

    .passwordLimit {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $third-color;
      width: 80%;
      margin-top: -20px;
      margin-left: 20px;
      margin-bottom: 40px;
    }
    .passwordField,
    .passwordFieldNew {
      position: relative;

      .passwordEye {
        position: absolute;
        right: 10px;
        top: 15px;
        cursor: pointer;
      }

      #checkbox,
      #checkboxRepeat {
        display: none;
      }

      :checked + label::before {
        content: url("../../images/cross-eye.svg");
        position: absolute;
        right: 10px;
        top: 13px;
        cursor: pointer;
      }
    }
  }
  span {
    text-align: right;
    margin-bottom: 30px;
  }
}
