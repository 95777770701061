@import "../../../../style//main.scss";

.settingContent {
  .accountSettings,
  .preferenceSettings {
    h2 {
      color: $third-color;
    }

    .settings {
      margin: 20px 0 40px;

      a,
      .switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $primary-color;
        text-decoration: none;
        background: #ffffff;
        border: 1px solid #e8e8ea;
        box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
        border-radius: 4px;
        padding: 10px 16px;

        @media only screen and (min-width: 600px) {
          width: 390px;
        }

        &:nth-child(1) {
          margin-bottom: 10px;
        }

        &:nth-child(2) {
          margin-bottom: 10px;
        }

        &:nth-child(3) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
