@import "../../style/main.scss";

.signIn {
  padding: 20px;
  @media only screen and (min-width: 600px) {
    width: 70%;
    margin: auto;
    padding: 0;
  }

  @media only screen and (min-width: 992px) {
    width: 70%;
  }

  .popupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }
  .emailPopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 95%;
    height: 740px;
    z-index: 4;

    .emailHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $third-color;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }
    }
  }

  .emailPopup1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 95%;
    height: 740px;
    z-index: 99;

    .emailHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $third-color;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }
    }
  }

  h2 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: $primary-color;
    margin-top: -25px;
    width: 80%;
    margin: auto;
    margin-top: -20px;
    margin-bottom: 60px;

    @media only screen and (min-width: 600px) {
      width: 100%;
    }
  }

  form {
    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 20px;

      input {
        padding: 10px 14px;
      }
    }

    .passwordField {
      position: relative;

      .passwordEye {
        position: absolute;
        right: 10px;
        top: 15px;
        cursor: pointer;
      }

      #checkbox {
        display: none;
      }

      :checked + label::before {
        content: url("../../images/cross-eye.svg");
        position: absolute;
        right: 10px;
        top: 13px;
        cursor: pointer;
      }
    }

    button {
      width: 100%;
    }

    .forgotPassword {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-decoration: underline;
      text-align: center;
      cursor: pointer;
      color: $secondary-color;
      margin: 20px 0 40px;
    }
  }

  .businessInside {
    background: #ffffff;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
    border-radius: 10px;
    position: relative;
    margin-top: 30px;

    @media only screen and (min-width: 768px) {
    }

    .businessContent {
      padding: 20px;

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        text-align: left;
        margin: 0;
        margin-bottom: 10px;
      }

      p {
        color: $third-color;
      }

      .icons {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        img {
          width: 100%;
        }

        @media only screen and (min-width: 768px) {
          flex-direction: row;
        }

        .appMobile,
        .googleMobile {
          @media only screen and (min-width: 768px) {
            display: none;
          }
        }

        .appDesktop,
        .googleDesktop {
          display: none;
          @media only screen and (min-width: 768px) {
            display: block;
          }
        }
      }
    }
  }
}
