@import "../../../../../style/main.scss";

.feedback {
  h2 {
    color: $third-color;
    margin-bottom: 20px;
  }

  .field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $primary-color;
    text-decoration: none;
    background: #ffffff;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
    border-radius: 4px;
    padding: 10px 16px;

    @media only screen and (min-width: 600px) {
      width: 390px;
    }
  }
}

.feedbackPage {
  position: relative;

  @media only screen and (min-width: 600px) {
    width: 100%;
  }

  .popupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }
  .emailPopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 350px;
    height: 543px;
    z-index: 4;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      top: 0;
    }

    .emailHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $third-color;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }
    }
  }
  h2 {
    color: $third-color;
  }

  h5 {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $third-color;
    margin: 20px 0 4px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .errorMessage {
      font-size: 0.75rem;
      color: #d32f2f;
      margin-top: 10px;
    }

    .MuiSvgIcon-root {
      width: 44px;
      height: 44px;
    }

    .css-1c99szj-MuiRating-icon {
      color: #ffba09;
    }

    .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
      width: 397px;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .sendFeedbackBtn {
      background: $secondary-color;
      box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
      border-radius: 10px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      cursor: pointer;
      width: 180px;
      height: 42px;
    }
  }
}
