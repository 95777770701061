@import "../../../../style/main.scss";

.rightSwipeContent {
  width: auto;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (min-width: 992px) {
    width: 100%;
    padding-top: 40px;
  }

  @media only screen and (min-width: 1400px) {
    width: 70%;
  }

  .popupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .emailPopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 100%;
    height: auto;
    z-index: 4;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      top: 0;
    }

    .emailHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $third-color;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }
    }
  }

  .swipes {
    list-style-type: none;
    margin-top: 20px;
    height: 90vh;
    overflow-y: scroll;

    @media only screen and (min-width: 768px) {
      width: 50%;
      margin-top: 0;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;

      &:nth-child(1) {
        margin-top: 0;
      }

      .matchInfo {
        display: flex;
        align-items: center;
        gap: 10px;

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: normal;
          margin: 0;
        }

        h6 {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          color: #0066ff;
          font-style: normal;
          font-weight: 600;
          background: rgb(0 102 255 / 20%);
          border-radius: 4px;
          text-transform: uppercase;
          padding: 4px;
          width: fit-content;
          margin-bottom: 4px;
        }
      }

      .matchFunctions {
        display: flex;
        align-items: baseline;
        gap: 5px;

        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .Mui-checked {
          color: $third-color;
        }
      }
    }
  }
}
