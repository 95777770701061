@import "../../../../../style/main.scss";

.MuiTabPanel-root {
  padding: 0 !important;
  @media only screen and (min-width: 768px) {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .deletePopupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }
  .deleteConfirm {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 350px;
    height: 543px;
    z-index: 4;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      top: 0;
    }

    .deleteHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $third-color;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }

      .chooseOptio {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 20px;

        .delete {
          background: #df1515;
          box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
          border-radius: 10px;
          text-align: center;
          padding: 10px 25px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #fff;
          cursor: pointer;
        }

        .cancel {
          background: $primary-color;
          box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
          border-radius: 10px;
          text-align: center;
          padding: 10px 25px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .EditPopupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .EditPopupContent {
    opacity: 1;
    transform: translateX(0%);
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 300px;
    z-index: 4;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #e8e8ea;
    box-shadow: -2px 0px 30px rgba(4, 0, 21, 0.1);
    overflow-y: scroll;

    @media only screen and (min-width: 768px) {
      width: 350px;
    }

    .EditPopupHeader {
      h3 {
        color: $primary-color;
        font-weight: 600;
        padding: 20px 20px 0;
      }
      .closePopup {
        position: absolute;
        top: 25px;
        right: 20px;
      }
    }
  }

  .editPopupInside {
    margin-top: 20px;
    border-top: 1px solid rgba(127, 125, 135, 0.5);
    padding: 20px;

    .editJobForm {
      .MuiFormControl-root {
        width: 100%;
      }

      .MuiAutocomplete-root {
        width: 100%;
        margin-top: 20px;

        .MuiInputBase-root {
          padding: 5px 0 0px 10px;
        }
      }

      h5 {
        margin-top: 40px;
        margin-bottom: 4px;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $third-color;
      }

      .workType,
      .workPlace {
        .content {
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 5px;
          margin-bottom: 0;

          .style {
            input.chk-btn {
              display: none;
            }
            input.chk-btn + label {
              cursor: pointer;
              background: #b0afb5;
              border-radius: 4px;
              font-family: $primary-font;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              color: #e8e8ea;
              padding: 4px 6px;
            }
            input.chk-btn:not(:checked) + label:hover {
              background-color: $primary-color;
            }
            input.chk-btn + label:active,
            input.chk-btn:checked + label {
              background: $primary-color;
            }
          }
        }
      }

      .level {
        display: flex;
        flex-direction: column;

        .levelStyle {
          label {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: 1px solid #7f7d87;
            border-radius: 4px;
            padding: 10px 16px;
            margin-bottom: 10px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $primary-color;
            cursor: pointer;

            .style {
              display: flex;
              flex-direction: column;

              span {
                font-family: $primary-font;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: $third-color;
              }
            }
          }
        }
      }

      .salaryCompetitive {
        height: 60px;
        label {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: $primary-color;
          display: flex;
          flex-direction: row-reverse;
          float: left;
          margin: 6px 0 5px;
          gap: 6px;
        }
      }

      .contactPerson,
      .links {
        .title {
          display: flex;
          align-items: baseline;
          justify-content: space-between;
          margin-bottom: 10px;

          button {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #0066ff;
            background: transparent;
            border: none;
            cursor: pointer;
          }
        }
        .MuiFormControl-root {
          margin-bottom: 15px;
        }
      }

      .jobDescription {
        margin-top: 60px;

        .ck-toolbar_grouping {
          width: 100%;
        }
      }

      .editJobBtn {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        width: 100%;
        background: #0066ff;
        box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
        border-radius: 10px;
        border: none;
        height: 42px;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }

  .content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 20px;
    margin-bottom: 40px;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .cards {
    @media only screen and (min-width: 768px) {
      width: 502px;
    }

    @media only screen and (min-width: 1300px) {
      width: 420px;
    }

    @media only screen and (min-width: 1900px) {
      width: 502px;
    }

    .cardStyle {
      position: relative;
      margin-top: 20px;
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid #e8e8ea;
      box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
      border-radius: 10px;
      margin-bottom: 10px;
      text-decoration: none;

      @media only screen and (min-width: 768px) {
        margin: 0;
      }
      .optionContent {
        position: absolute;
        right: 10px;
        top: 10px;
        height: fit-content;
        background: #e8e8ea;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        z-index: 1;

        .options {
          ul {
            list-style-type: none;
            li {
              font-family: $primary-font;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: $primary-color;
              padding: 12px 16px;

              &:hover {
                background: #b0afb5;
                cursor: pointer;
              }
            }
          }
        }
      }

      .companyLogo {
        height: 130px;
        width: 80px;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @media only screen and (min-width: 500px) {
          width: 30%;
        }

        @media only screen and (min-width: 768px) {
          width: 20%;
        }

        @media only screen and (min-width: 1300px) {
          width: 120px;
          height: 130px;
        }
      }

      .cardInfo {
        position: relative;
        padding-left: 10px;
        width: 100%;

        @media only screen and (min-width: 1300px) {
          padding-left: 20px;
        }

        .dot {
          position: absolute;
          right: 10px;
          cursor: pointer;

          @media only screen and (min-width: 1300px) {
            right: 20px;
          }
        }

        h5 {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 16px;

          text-transform: uppercase;
          display: flex;
          align-items: baseline;
          gap: 2px;

          &.activeJob {
            color: #14c364;

            &::before {
              content: "\A";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #14c364;
              display: inline-block;
            }
          }

          &.inactiveJob {
            color: #df1515;

            &::before {
              content: "\A";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #df1515;
              display: inline-block;
            }
          }
        }

        h2 {
          font-weight: 600;
          margin-bottom: 10px;
          word-break: break-all;
        }

        .applicantsDate {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 18px;
            color: #ffffff;
            background-color: $primary-color;
            border-radius: 4px;
            padding: 4px 6px;
          }

          span {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $third-color;
            margin-right: 10px;

            @media only screen and (min-width: 1300px) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}


.addMatches {
  color: #0066FF;
  cursor: pointer;
  font-size: 12px;
  display: block;

}

.otherTags {
  margin-bottom: 40px;

  h6 {
    color: $third-color;
    margin-bottom: 4px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
    margin-bottom: 0;
    flex-direction: row;
    margin-bottom: 20px;

    .style {
      input.chk-btn {
        display: none;
      }

      input.chk-btn + label {
        cursor: pointer;
        background: #b0afb5;
        border-radius: 4px;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #e8e8ea;
        padding: 4px 6px;
      }

      input.chk-btn:not(:checked) + label:hover {
        background-color: $primary-color;
      }

      input.chk-btn + label:active,
      input.chk-btn:checked + label {
        background: $primary-color;
      }
    }
  }
}

.ck-editor__editable {
  max-height: 100px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;

}