@import "../../../style/main";


.UserProfile {
  position: absolute;
  right: 0;
  top: 0;
  width: 392px;
  height: 100vh;
  overflow: scroll;
  box-shadow: -2px 0px 30px rgba(4, 0, 21, 0.1);
  background-color: white;
  z-index: 50;
}

.UserProfileHeader {
  height: 86px;
  width: calc(100% - 24px);
  border-bottom: 1px solid rgba(127, 125, 135, 0.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: black;
  padding-left: 24px;
}

.closePopup {
  margin-right: 10px;
  margin-top: 1px;
}

.UserProfileInside {
  width: 344px;
  margin-left: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  height: calc(100vh - 327px - 87px);


  @media only screen and (max-width: 992px) {
    height: unset;
  }
}

.UserProfileImageContainer {
  position: relative;
  width: 392px;
  height: 287px;

  @media only screen and (max-width: 992px) {
    width: 100vw;
  }
}

.UserProfileImage {
  width: 392px;
  height: 287px;
  position: relative;
  display: flex;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    width: 100vw;
  }
}

.UserProfileImageOnly {
  width: 392px;
  height: 287px;
  object-fit: cover;
  cursor: pointer;
  align-self: center;
  flex-shrink: 0;

  @media only screen and (max-width: 992px) {
    width: 100vw;
  }

}

.UserProfileImageLines {
  display: flex;
  position: absolute;
  top: 26px;
  left: 20px;
  z-index: 3;
}

.UserProfileImageLine {
  width: 28px;
  height: 4px;
  box-shadow: 0px 2px 10px rgba(4, 0, 21, 0.1);
  border-radius: 16.1333px;
  margin-right: 4px;
}

.UserProfileBookmark {
  position: absolute;
  z-index: 3;
  top: 18px;
  right: 20px;
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));
  cursor: pointer;
}

.cvContainer {
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  right: 10px;
  bottom: 10px;
  z-index: 3;
  display: flex;
  align-items: center;
  padding: 3px 7px;
  gap: 5px;
  text-decoration: underline;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;
}

.UserProfileName {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 16px;
}

.tags {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 10px;

  .match {
    background: #e1ebfb;
    border-radius: 4px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: $secondary-color;
    padding: 4px 6px;
    text-transform: uppercase;
    height: 18px;
    display: flex;
    align-items: center;
  }

  .jobSeeking {
    background: #c4f1d9;
    border-radius: 4px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #14c364;
    text-transform: uppercase;
    padding: 4px 6px;
    width: -moz-fit-content;
    width: fit-content;
    height: 18px;
    display: flex;
    align-items: center;
  }

  .openToWork {
    color: #CE8D0F;
    background: #FFEEC0;
    border-radius: 4px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    padding: 4px 6px;
    width: -moz-fit-content;
    width: fit-content;
    height: 18px;
    display: flex;
    align-items: center;
  }

  .networking {
    color: #10C9E2;
    background: #D7F3F6;
    border-radius: 4px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    padding: 4px 6px;
    width: -moz-fit-content;
    width: fit-content;
    height: 18px;
    display: flex;
    align-items: center;
  }
}

.cvImage {
  height: 17px;
}

.UserProfileInfoWrap {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.UserProfileInfo {
  margin-top: 6px;
  margin-bottom: 17px;
}

.allTags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 24px;
}

.darkTag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: white;
  background-color: #040015;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
}

.miniTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7F7D87;
}

.about {
  margin-bottom: 16px;
  overflow-wrap: break-word;
}

.buttons {
  margin-top: 10px;
  width: 100%;
}

.UserProfileLink {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #040015 !important;
  margin-bottom: 10px;
}

.UserProfileSendMessage {
  background: #FFFFFF;
  border: 1px solid rgba(0, 102, 255, 0.05);
  box-shadow: 0px 2px 15px rgba(4, 0, 21, 0.1);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #0066FF;
  position: relative;
  left: 35px;
  width: 280px;
  height: 42px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.UserProfileReport {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #7F7D87;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 20px;

}

.UserProfileLine {
  background-color: #E8E8EA;
  height: 1px;
  border: none;
}

@media only screen and (max-width: 992px) {
  .UserProfile {
    width: 100vw;
  }

  .UserProfileHeader {
    width: unset;
  }
}

.aboutLinkWrap {
  margin-bottom: 102px;
}

.moreInfoButton {
  position: absolute;
  width: 44px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #E8E8EA;
  box-shadow: 0px 2px 10px rgba(4, 0, 21, 0.15);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 10px;
  top: 300px;
  z-index: 10;

  @media only screen and (min-width: 992px) {
    display: none;
  }
}
