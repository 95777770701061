@import "../../../../style/main.scss";

.reportPopup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fafafa;
  border: 1px solid #e8e8ea;
  box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
  transform: translate(0) !important;
  border-radius: 10px;
  width: 350px;
  height: 555px;
  z-index: 4;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    width: 550px;
    //height: 740px;
    top: 0;
  }

  .reportHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;

    img {
      position: absolute;
      right: 30px;
      top: 45px;
    }

    h3 {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: $primary-color;
      width: 80%;
      margin-top: 40px;
      padding: 0;

      @media only screen and (min-width: 768px) {
        width: 100%;
      }
    }

    p {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $primary-color;
      width: 80%;
      margin: 40px 0 20px;

      @media only screen and (min-width: 768px) {
        width: 55%;
      }
    }

    .MuiFormControl-root {
      width: 90%;

      @media only screen and (min-width: 768px) {
        width: 60%;
      }

      .MuiFormControlLabel-root {
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 0;
        border: 1px solid #7f7d87;
        border-radius: 4px;
        padding: 10px 16px;
        margin-bottom: 10px;

        .MuiButtonBase-root {
          padding: 0;
        }
      }
    }

    .reportBtns {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin-top: 40px;

      // @media only screen and (min-width: 768px) {
      //   width: 100%;
      // }

      .close {
        width: 50%;
        background: #ffffff;
        border: 1px solid rgba(0, 102, 255, 0.05);
        box-shadow: 0px 2px 15px rgb(4 0 21 / 10%);
        border-radius: 10px;
        padding: 10px 0;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $secondary-color;
        cursor: pointer;
      }

      .report {
        width: 50%;
        background: #0066ff;
        box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
        border-radius: 10px;
        border: none;
        padding: 10px 0;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.popupBackground {
  background: rgba(176, 175, 181, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  cursor: pointer;
}

.card {
  left: 333px;
  right: 33px;
  background: #fff;
  position: absolute;
  transition: all 0.5s ease-out;
  border-radius: 10px;

  @media only screen and (max-width: 1300px) {
    right: 90px;
  }

  @media only screen and (max-width: 991px) {
    left: 20px;
    right: 20px;
    box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);

  }

  .cardLook {
    display: flex;
    border: 1px solid #E8E8EA;
    border-radius: 10px;

    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }

    @media only screen and (max-width: 992px) {
    //
    }

    .leftSide {
      width: 390px;
      position: relative;
      background-color: #fafafa;

      @media only screen and (max-width: 700px) {
        width: 100%;
        background-color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }



      .image {
        width: 100%;
        height: 50vh;
        object-fit: cover;
        border-radius: 10px 0 0 0;
        margin-bottom: 20px;

        @media only screen and (max-width: 700px) {
          border-radius: 10px 10px 0 0;
        }

        @media only screen and (max-width: 992px) {
          height: 287px;
        }
      }

      .mobileBookmark {
        display: none;
        @media only screen and (max-width: 700px) {
          display: flex;
          position: absolute;
          top: 18px;
          z-index: 10;
          right: 18px;
          filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));

        }
      }

      .mobileResumeLink {
        display: none;
        @media only screen and (max-width: 700px) {
          display: flex;
          position: absolute;
          justify-Content: center;
          align-items: center;
          gap: 5px;
          white-space: nowrap;
          background-color: #fff;
          padding: 3px 7px;
          border-radius: 4px;
          bottom: 10px;
          right: 10px;
          text-decoration-color: #040015;
          font-size: 12px;
          filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));
          z-index: 5;

          .mobileResumeText {
            white-space: nowrap;
            color: #040015;
            font-weight: 600;
            text-decoration-color: #000000;
            text-decoration: underline;
          }
        }
      }

      .buttons {
        padding: 40px 40px 0 40px;
        margin-bottom: 20px;
        max-width: 300px;
        left: 0;
        right: 0;
        margin: auto;

        @media only screen and (max-width: 700px) {
          display: none;
        }

        .report {
          width: 100%;
          margin: 20px 0 20px 0;
          border: 0;
          background-color: #fafafa;
          color: #7F7D87;
          font-weight: 600;
          text-decoration-line: underline;
        }

        .hrTag {
          border-top: 1px solid #E8E8EA;
        }

        .sendMessage {
          width: 100%;
          margin: 0 0 20px 0;
          padding: 10px;
          border: 0;
          background: #fff;
          color: #0066FF;
          font-weight: 600;
          font-size: 16px;
          box-shadow: 0px 0px 10px 0px rgba(207,203,207,1);
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }

    .rightSide {
      width: calc(100% - 390px);
      padding: 25px;
      border-left: 1px solid #E8E8EA;
      height: calc(100vh - 300px) !important;
      background-color: #fafafa;
      position: relative;

      @media only screen and (max-width: 700px) {
        width: 100%;
        padding: 0;
        background-color: white;

      }

      @media only screen and (max-width: 992px) {
        height: calc(100vh - 410px) !important; // for fallback purposes
        height: calc(100dvh - 410px) !important;
        transition: height 0.25s ease-in-out;
        overflow: hidden;
      }

        .likeTag {
        position: absolute;
        top: 20px;
        left: 30px;
          z-index: 10;
      }

      .mobileWrap {
        @media only screen and (max-width: 700px) {
          overflow: hidden;
          height: calc(100vh - 418px);
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }

      .nameBookmarkWrap {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 700px) {
          margin: 0 20px;
        }

        .name {
          font-size: 24px;
          font-weight: 600;
          color: #040015;
          margin-bottom: 10px;
        }

        .bookmark {
          z-index: 10;
          filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));
          position: relative;
          cursor: pointer;
          @media only screen and (max-width: 700px) {
            display: none;
          }
        }
      }

      .matchJobWrap {
        display: flex;
        gap: 5px;
        margin-bottom: 5px;

        @media only screen and (max-width: 700px) {
          margin: 0 20px 5px 20px;
        }

        .matchStatus {
          padding: 5px;
          color: #0066FF;
          background: #E1EBFB;
          font-weight: 600;
          font-size: 12px;
          border-radius: 4px;
        }

        .matchStatusTag {
          padding: 5px;
          font-weight: 600;
          font-size: 12px;
          border-radius: 4px;
          text-transform: uppercase;
          height: unset;
        }
      }

      .info {
        margin-bottom: 16px;

        @media only screen and (max-width: 700px) {
          margin: 0 20px 15px 20px;
        }

        .infoWrap {
          align-items: center;
          justify-content: center;

          .icon {
            margin-right: 5px;
          }
        }
      }

      .tagResumeWrap {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        @media only screen and (max-width: 700px) {
          margin: 0 20px 24px 20px;
        }

        .tagUl {
          display: flex;
          flex-wrap: wrap;
          margin-right: 20px;

          .tagLi {
            list-style: none;
            margin-right: 5px;
            background: #040015;
            color: #fff;
            padding: 5px;
            border-radius: 4px;
            font-weight: 600;
            margin-bottom: 5px;
          }
        }

      }

      .for-resume-link {
        display: flex;
        justify-content: end;
      }

      .resumeLink {
        display: flex;
        justify-Content: center;
        align-items: center;
        gap: 5px;
        white-space: nowrap;
        text-decoration-color: #000000;
        cursor: pointer;
        box-shadow: 0px -2px 10px rgb(4 0 21 / 10%);
        border-radius: 5px;
        width: 65px;
        height: 36px;

        @media only screen and (max-width: 700px) {
          display: none;
        }

        .resumeText {
          white-space: nowrap;
          color: #040015;
          font-weight: 600;
        }
      }

      .aboutLinkWrap {
        height: 210px;
        overflow: scroll;
        margin-bottom: 20px;
        position: relative;

        @media only screen and (max-width: 1500px) {
          height: 170px;
        }

        @media only screen and (max-width: 700px) {
          margin: 0 20px 24px 20px;
          height: unset;
          overflow: unset;
        }

        .about {
          margin-bottom: 24px;
          overflow-wrap: break-word;

          .aboutText {
            color: #7F7D87;
            font-size: 12px;
          }

          .aboutContent {
            color: #040015;
            font-size: 16px; 
          }
        }

        .links {
          .linksText {
            color: #7F7D87;
            font-size: 12px;
          }

          ul {
            .linksLi {
              margin-bottom: 10px;

              .linksA {
                font-size: 16px;
                color: #040015;
                text-decoration-line: underline;
                font-weight: 600;
              }
            }
          }
        }
      }

      .mobileButtons {
        padding: 0 20px 0 20px;
        margin-bottom: 20px;
        display: none;

        @media only screen and (max-width: 700px) {
          display: flex;
          flex-direction: column;
          margin-bottom: 60px;
        }

        .mobileSendWrap {
          border-bottom: 1px solid #E8E8EA;
          padding: 0 0 20px 0;

          .mobileSendMessage {
            width: 100%;            
            padding: 10px;
            border: 0;
            background: #fff;
            color: #0066FF;
            font-weight: 600;
            font-size: 16px;
            box-shadow: 0px 0px 10px 0px rgba(207,203,207,1);
            border-radius: 10px;
            cursor: pointer;
          }
        }

        .mobileReport {
          width: 100%;
          margin: 20px 0 20px 0;
          border: 0;
          background: #fff;
          color: #7F7D87;
          font-weight: 600;
          text-decoration-line: underline;
        }
      }

      .skipLinkWrap {
        display: flex;
        width: calc(100% - 50px);
        justify-content: center;
        bottom: 10px;
        position: absolute;


        @media only screen and (max-width: 992px) {
          bottom: -4px;
          width: 100%;
        }

        .skipButton {
          width: 50%;
          padding: 20px;
          border-radius: 10px 0 0 10px;
          border: 0;
          background: #E8E8EA;
          color: #7F7D87;
          align-items: center;
          gap: 10px;
          font-size: 16px;
          font-weight: 600;
          max-width: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          @media only screen and (max-width: 700px) {
            border-radius: 0 0 0 10px;
          }
        }

        .likeButtonWrap {
          width: 50%;
          padding: 20px;
          border-radius: 0 10px 10px 0;
          border: 0;
          background: #0066FF;
          color: #FFFFFF;
          align-items: center;
          gap: 10px;
          font-size: 16px;
          font-weight: 600;
          max-width: 250px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          @media only screen and (max-width: 700px) {
            border-radius: 0 0 10px 0;
          }
        }
      }
    }
  }
}

.swiped-card {
  transform: rotate(325deg);
  transform: rotate(-30deg);
  left: 300px !important;
  opacity: 0.1;
  transition: all 0.5s ease-in-out;
}

.card-to-skip {
  //transform: rotate(325deg);
  transform: rotate(30deg);
  left: 300px !important;
  opacity: 0.1;
  transition: all 0.5s ease-in-out;
  transform-origin: 0% 0%;
}

.marginSimulatorDiv {
  display: none;

  @media only screen and (max-width: 992px) {
   display: flex;
   width: 100%;
   height: 20px;
   background: transparent;
  }
}

.moreInfoButton {
  position: absolute;
  width: 44px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #E8E8EA;
  box-shadow: 0px 2px 10px rgba(4, 0, 21, 0.15);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 10px;
  top: 300px;
  z-index: 10;

  @media only screen and (min-width: 992px) {
    display: none;
  }
}


.UserCardImage {
  width: 100%;
  height: 287px;
  position: relative;

  @media only screen and (min-width: 992px) {
    height: 70%;
  }

}

.UserCardImageOnly {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 10px;


  @media only screen and (max-width: 992px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.UserCardImageLinesMobile {
  display: flex;
  position: absolute;
  top: 26px;
  left: 20px;
  z-index: 3;
}

.UserCardImageLines {
  display: flex;
  position: absolute;
  bottom: 26px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));
}

.UserCardImageLine {
  width: 28px;
  height: 4px;
  box-shadow: 0px 2px 10px rgba(4, 0, 21, 0.1);
  border-radius: 16.1333px;
  margin-right: 4px;
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));
}

.allTagsCard {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 24px;

  @media only screen and (max-width: 992px) {
    margin-left: 20px;
  }
}

.rightArrow {
  position: absolute;
  right: 20px;
  bottom: 8px;
  cursor: pointer;
  z-index: 3;
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));
}

.leftArrow {
  position: absolute;
  left: 20px;
  bottom: 8px;
  cursor: pointer;
  transform: rotate(180deg);
  z-index: 3;
  filter: drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));

}
.likeTag {
  position: absolute;
  top: 20px;
  left: 0px;
  z-index: 10;
}

.skipTag {
  position: absolute;
  top: 20px;
  right: 0px;
  z-index: 10;
}