@import "../../../../style/main.scss";

.jobs {
  padding: 40px 20px 40px 20px;

  @media only screen and (min-width: 992px) {
    width: 100%;
  }

  @media only screen and (max-width: 992px) {
    padding: 0;
  }

  .contentCards {
    margin-top: 60px;
    overflow-y: scroll;


    @media only screen and (max-width: 992px) {
      margin-top: -45px;
    }
  }

  .searchedList {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .FilterPopupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .FilterPopupContent {
    opacity: 1;
    transform: translateX(0%);
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 300px;
    z-index: 4;
    cursor: pointer;
    background: #fff;
    border: 1px solid #e8e8ea;
    box-shadow: -2px 0px 30px rgb(4 0 21 / 10%);

    overflow-y: scroll;

    @media only screen and (min-width: 768px) {
      width: 350px;
    }

    .FilterPopupHeader {
      h3 {
        color: $primary-color;
        font-weight: 600;
        padding: 20px 20px 0;
        img {
          margin-right: 10px;
        }
      }
      .closePopup {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }

  .filterPopupInside {
    margin-top: 20px;
    border-top: 1px solid rgba(127, 125, 135, 0.5);
    padding: 20px;
    overflow-y: scroll;

    .jobPreview {
      background-color: #040015;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .UserProfileImageContainer {
        width: 260px;

        @media only screen and (min-width: 992px) {
          width: 310px;
        }

      }

      .UserProfileImage {
        width: 260px;

        @media only screen and (min-width: 992px) {
          width: 310px;
        }

      }

      .UserProfileImageOnly {
        width: 260px;

        @media only screen and (min-width: 992px) {
          width: 310px;
        }
      }

      .jobPreviewContent {
        padding: 20px;

        h2 {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 30px;
          color: #fff;
          padding: 15px 0 0;
        }

        .levels {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #fff;
        }

        .location {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #fff;
          margin-top: 5px;
        }

        h3 {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: $third-color;
          margin: 10px 0;
        }

        .companyLogoName {
          display: flex;
          align-items: center;
          gap: 5px;

          .logo {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            object-fit: cover;
          }

          .name {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #fff;
            text-decoration: underline;
          }
        }

        .jobDesc,
        .links,
        .jobApplicantPage,
        .contactPerson,
        .salary {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #fff;
        }
      }

      .react-multi-carousel-list {
        position: relative;
        ul {
          li {
            img {
              height: 280px;
            }
          }
        }

        .react-multi-carousel-dot-list {
          position: absolute;
          top: 10px;
          left: -30%;

          .react-multi-carousel-dot button {
            width: 28px;
            height: 4px;
            box-shadow: 0px 2px 10px rgba(4, 0, 21, 0.1);
            border-radius: 16.1333px;
          }

          .react-multi-carousel-dot--active button {
            border-color: #ffffff;
          }
        }
      }
    }

    .adCostReview {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $third-color;
      margin-top: 40px;
      text-align: center;

      span {
        color: $primary-color;
        font-weight: 600;
        margin-left: 2px;
      }
    }

    .MuiAutocomplete-root {
      width: 100%;
      margin-top: 20px;

      .MuiInputBase-root {
        padding: 5px 0 0px 10px;
      }
    }

    .MuiFormControl-root {
      width: 100%;
    }

    h5 {
      margin-top: 40px;
      margin-bottom: 4px;
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $third-color;
    }

    .workType,
    .workPlace {
      .content {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 5px;
        margin-bottom: 0;
        flex-direction: row;

        .style {
          input.chk-btn {
            display: none;
          }
          input.chk-btn + label {
            cursor: pointer;
            background: #b0afb5;
            border-radius: 4px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #e8e8ea;
            padding: 4px 6px;
          }
          input.chk-btn:not(:checked) + label:hover {
            background-color: $primary-color;
          }
          input.chk-btn + label:active,
          input.chk-btn:checked + label {
            background: $primary-color;
          }
        }

        // label {
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   cursor: pointer;
        //   background: #b0afb5;
        //   border-radius: 4px;
        //   font-family: $primary-font;
        //   font-style: normal;
        //   font-weight: 600;
        //   font-size: 12px;
        //   line-height: 18px;
        //   color: #e8e8ea;
        //   padding: 4px 6px;
        //   height: 26px;

        //   input[type="checkbox"] {
        //     display: none;
        //   }
        // }
      }
    }

    .level {
      display: flex;
      flex-direction: column;

      .levelStyle {
        label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border: 1px solid #7f7d87;
          border-radius: 4px;
          padding: 10px 16px;
          margin-bottom: 10px;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: $primary-color;
          cursor: pointer;

          .style {
            display: flex;
            flex-direction: column;

            span {
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: $third-color;
            }
          }
        }
      }
    }

    .salaryCompetitive {
      height: 60px;
      label {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $primary-color;
        display: flex;
        flex-direction: row-reverse;
        float: left;
        margin: 6px 0 6px;
        gap: 6px;
      }
    }

    .contactPerson,
    .links {
      .title {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;

        button {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #0066ff;
          background: transparent;
          border: none;
          cursor: pointer;
        }
      }
      .MuiFormControl-root {
        margin-bottom: 15px;
      }

      .text {
        p {
          font-weight: 600;
        }
      }

      .link {
        a {
          color: #fff;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }

    .link {
      color: #fff;
      font-weight: 600;
      display: flex;
      flex-direction: column;
    }

    .workTypesNext {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      p {
        background: #fff;
        border-radius: 4px;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        color: $primary-color;
        padding: 4px 6px;
      }
    }

    .workLevelNext {
      display: flex;
      gap: 10px;
      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #fff;
      }
    }

    .jobDescription {
      margin-top: 60px;

      .ck-toolbar_grouping {
        width: 100%;
      }
    }

    .adCost {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $third-color;
      margin-top: 80px;
      text-align: center;

      span {
        color: $primary-color;
        font-weight: 600;
        margin-left: 2px;
      }
    }

    .reviewPublich {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      width: 100%;
      background: #0066ff;
      box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
      border-radius: 10px;
      border: none;
      height: 42px;
      cursor: pointer;
    }

    .inactiveJob {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $third-color;
      margin-top: 20px;
      width: 100%;
      background: none;
      border: none;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-direction: column-reverse;
    gap: 20px;
    margin-bottom: 40px;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
  }

  .cards {
    @media only screen and (min-width: 768px) {
      width: 502px;
    }

    @media only screen and (min-width: 1300px) {
      width: 420px;
    }

    @media only screen and (min-width: 1900px) {
      width: 502px;
    }

    .cardStyle {
      position: relative;
      // margin-top: 20px;
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid #e8e8ea;
      box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
      border-radius: 10px;
      // margin-bottom: 10px;
      text-decoration: none;

      @media only screen and (max-width: 992px) {
        margin-right: 20px;
        margin-left: 20px;
      }
      .optionContent {
        position: absolute;
        right: 10px;
        top: 10px;
        height: fit-content;
        background: #e8e8ea;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        z-index: 1;

        .options {
          ul {
            list-style-type: none;
            li {
              font-family: $primary-font;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: $primary-color;
              padding: 8px 16px;

              &:hover {
                background: #b0afb5;
                cursor: pointer;
              }
            }
          }
        }
      }

      .companyLogo {
        height: 130px;
        width: 80px;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @media only screen and (min-width: 500px) {
          width: 30%;
        }

        @media only screen and (min-width: 768px) {
          width: 20%;
        }

        @media only screen and (min-width: 1300px) {
          width: 120px;
          height: 130px;
        }
      }

      .cardInfo {
        position: relative;
        padding-left: 10px;
        width: 100%;

        @media only screen and (min-width: 1300px) {
          padding-left: 20px;
        }

        .dot {
          position: absolute;
          right: 10px;
          cursor: pointer;

          @media only screen and (min-width: 1300px) {
            right: 20px;
          }
        }

        h5 {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 16px;

          text-transform: uppercase;
          display: flex;
          align-items: baseline;
          gap: 2px;

          &.activeJob {
            color: #14c364;

            &::before {
              content: "\A";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #14c364;
              display: inline-block;
            }
          }

          &.inactiveJob {
            color: #df1515;

            &::before {
              content: "\A";
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #df1515;
              display: inline-block;
            }
          }
        }

        h2 {
          font-weight: 600;
          margin-bottom: 10px;
          word-wrap: break-all;
        }

        .applicantsDate {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #ffffff;
            background-color: $primary-color;
            border-radius: 4px;
            padding: 4px 6px;
          }

          span {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: $third-color;
            margin-right: 10px;

            @media only screen and (min-width: 1300px) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

}

.jobsContent {
  position: relative;
  overflow: hidden;
  margin-bottom: 0 !important;
  padding-bottom: 40px !important;
}

.createJob {
  position: fixed;
  right: 20px;
  cursor: pointer;
  width: 42px;
  height: 42px;
  bottom: 20px;
}

.addMatches {
  color: #0066FF;
  cursor: pointer;
  font-size: 12px;
  display: block;

}

.otherTags {
  margin-bottom: 40px;

  h6 {
    color: $third-color;
    margin-bottom: 4px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
    margin-bottom: 0;
    flex-direction: row;
    margin-bottom: 20px;

    .style {
      input.chk-btn {
        display: none;
      }

      input.chk-btn + label {
        cursor: pointer;
        background: #b0afb5;
        border-radius: 4px;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #e8e8ea;
        padding: 4px 6px;
      }

      input.chk-btn:not(:checked) + label:hover {
        background-color: $primary-color;
      }

      input.chk-btn + label:active,
      input.chk-btn:checked + label {
        background: $primary-color;
      }
    }
  }
}

.applicantName {
  font-size: 16px !important;
}

.ck-editor__editable {
  max-height: 100px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;

}

.ck-editor__editable_inline {
  min-height: 150px;
}