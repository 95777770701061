@import "../../../../../style/main.scss";

.pos {
  position: relative;

  .changeEmail {
    h2 {
      color: $third-color;
    }

    .emailFields {
      margin-top: 20px;
      flex-direction: column;
      margin-top: 20px;

      & > div {
        .MuiFormControl-root {
          width: 100%;
          margin-bottom: 20px;

          @media only screen and (min-width: 500px) {
            width: 390px;
            display: flex;
          }

          label {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            top: -5px;

            &.Mui-focused {
              color: $third-color;
            }
          }

          input {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $primary-color;
            padding: 10px 16px;
          }

          fieldset {
            &.MuiOutlinedInput-notchedOutline {
              border-color: #b0afb5;
              border-width: 1px;
            }
          }
        }

        &:nth-child(2) {
          margin-bottom: 40px;
        }
      }

      .updatePassword {
        background: $secondary-color;
        box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
        border-radius: 10px;
        border: none;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        cursor: pointer;
        width: 180px;
        height: 42px;
      }
    }
  }

  .popupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }
  .emailPopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 350px;
    height: 543px;
    z-index: 4;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      top: 0;
    }

    .emailHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $third-color;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }
    }
  }

  .otp {
    margin-top: 60px;
  }

  .MuiFormControl-root {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    @media only screen and (min-width: 600px) {
      width: 390px;
    }

    label {
      top: -5px;
    }

    input {
      padding: 10px 16px;
    }
  }
}
