
.NewMessageDrawer {
  top: 62px;
  width: 35vw;
  left: 32.5vw;
  height: 70vh;
  position: absolute;
  background-color: white;
  box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
  border-radius: 10px;
  min-width: 400px;

  @media only screen and (max-width: 992px) {
    top: 10px;
    height: calc(100vh - 20px); // fallback
    height: calc(100dvh - 20px);
    width: calc(100% - 50px);
    left: 10px;
    overflow: hidden;
  }
}

.NewMessageDrawerHeader {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.NewMessageDrawerHeaderClose {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.newMessageInner {
  margin-top: 10px;
  display: block;
  position: relative;
  margin-left: 10%;
  margin-right: 10%;

  .search {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;


    @media only screen and (max-width: 992px) {
      width: calc(100% - 60px);
      input {
        width: 100%;
      }
    }

  }
}

.list {
  margin-top: 40px;
  height: 100%;
  overflow: scroll;
}

.matchInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.matchPercentages {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #0066ff;
  font-style: normal;
  font-weight: 600;
  background: rgb(0 102 255 / 20%);
  border-radius: 4px;
  text-transform: uppercase;
  padding-left: 4px;
  padding-right: 4px;
  width: fit-content;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  margin-right: 6px;
  height: 22px;
}