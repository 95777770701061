@import "../../../style/main.scss";

.nav {
  box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.05);
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 999;

  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.96);

    .logo {
      img {
        width: 100%;
      }
    }

    .signInPopupBackground {
      background: rgba(176, 175, 181, 0.8);
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 3;
      cursor: pointer;
    }

    .signInPopupContent {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      top: 20%;
      left: 0;
      right: 0;
      background: #fafafa;
      border: 1px solid #e8e8ea;
      box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
      border-radius: 10px;
      width: 90%;
      z-index: 4;
      max-height: calc(100vh - 40px);


      @media only screen and (min-width: 768px) {
        width: 550px;
        height: 740px;
        overflow-y: scroll;
      }

      .signInPopupHeader {
        padding: 40px 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          width: 100%;
          text-align: center;
          font-size: 20px;
          font-weight: 500;
        }

        .closePopup {
          text-align: right;
          cursor: pointer;
          width: 100%;

          img {
            width: 5%;

            @media only screen and (min-width: 768px) {
              width: 2%;
            }
          }
        }
      }

      .signUpPopupHeader {
        padding: 40px 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .closePopup {
          text-align: right;
          cursor: pointer;
          width: 100%;

          img {
            width: 5%;

            @media only screen and (min-width: 600px) {
              width: 2%;
            }
          }
        }
      }

      .signInPopupInside {
        padding: 0 20px;
      }
    }

    .menu {
      display: none;
    }

    .authBtns {
      display: none;
    }

    .mobileMenu {
      svg {
        cursor: pointer;
      }

      .menuBackground {
        background: rgba(176, 175, 181, 0.8);
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 3;
        cursor: pointer;
      }

      .mobileContentMenu {
        position: fixed;
        right: 0;
        top: 0;
        // padding: 20px;
        background: #fff;
        height: 100%;
        width: 300px;
        border-right: 1px solid #e3dede;
        z-index: 3;

        ul {
          list-style-type: none;
          // margin-top: 20px;
          border-top: 1px solid #97979759;
          padding: 20px;

          li {
            padding-bottom: 24px;
            a {
              font-family: $primary-font;
              font-weight: bold;
              color: $primary-color;
              text-decoration: none;
            }
          }

          .authBtns {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .signUpBtn {
              font-family: $primary-font;
              font-weight: 600;
              background: $secondary-color;
              box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
              border-radius: 10px;
              padding: 10px 36px;
              color: #fff;
              width: 60px;
              text-align: center;
              cursor: pointer;
            }

            .signInBtn {
              font-family: $primary-font;
              font-weight: 600;
              border: 1px solid rgba(0, 102, 255, 0.05);
              box-shadow: 0px 2px 15px rgba(4, 0, 21, 0.1);
              border-radius: 10px;
              padding: 10px 36px;
              color: $secondary-color;
              width: 60px;
              text-align: center;
              cursor: pointer;
            }
          }
        }

        .mobileMenuHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;

          .mobileTitle {
            h3 {
              font-weight: 600;
            }
          }

          .closeMenu {
            svg {
              cursor: pointer;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 991px) {
      .menu {
        display: flex;
        gap: 40px;

        ul {
          display: flex;
          align-items: center;
          gap: 40px;
          list-style-type: none;

          li {
            a {
              font-family: "Poppins", sans-serif;
              font-weight: 600;
              font-size: 16px;
              color: $primary-color;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .mobileMenu {
        display: none;
      }

      .authBtns {
        display: flex;
        align-items: center;
        gap: 20px;

        .signUpBtn {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          background: $secondary-color;
          border-radius: 10px;
          box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
          border: 1px solid transparent;
          padding: 10px;
          color: #fff;
          width: 80px;
          text-align: center;
          cursor: pointer;
          transition: transform 0.5s ease;

          &:hover {
            transform: scale(1.1);
          }
        }

        .signInBtn {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          border: 1px solid rgba(0, 102, 255, 0.05);
          box-shadow: 0px 2px 15px rgba(4, 0, 21, 0.1);
          border-radius: 10px;
          padding: 10px;
          color: $secondary-color;
          width: 80px;
          text-align: center;
          cursor: pointer;
          transition: transform 0.5s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    @media only screen and (min-width: 1600px) {
      width: 60%;
      margin: auto;
    }
  }
}
