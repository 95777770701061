@import "../../../../../style/main.scss";

.deleteAccount {
  h2 {
    color: $third-color;
  }

  p {
    margin: 10px 0 40px;

    @media only screen and (min-width: 600px) {
      width: 390px;
    }
  }

  .deleteAccountBtn {
    background: #df1515;
    box-shadow: 0px 2px 20px rgb(0 102 255 / 10%);
    border-radius: 10px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    cursor: pointer;
    width: 180px;
    height: 42px;
  }
}
