@import "../../../style/main.scss";

.services {
  margin: 0px 0;

  @media only screen and (min-width: 992px) {
    margin: 30px 0;
  }

  @media only screen and (min-width: 1400px) {
    margin-top: 60px 0;
  }

  @media only screen and (min-width: 1600px) {
    margin: 60px 0 90px;
  }

  .signInPopupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .signInPopupContent {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 20%;
    left: 0;
    right: 0;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
    border-radius: 10px;
    width: 90%;
    z-index: 4;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      overflow-y: scroll;
    }

    .signInPopupHeader {
      padding: 40px 20px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
      }

      .closePopup {
        text-align: right;
        cursor: pointer;
        width: 100%;

        img {
          width: 5%;

          @media only screen and (min-width: 768px) {
            width: 2%;
          }
        }
      }
    }

    .signUpPopupHeader {
      padding: 40px 20px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .closePopup {
        text-align: right;
        cursor: pointer;
        width: 100%;

        img {
          width: 5%;

          @media only screen and (min-width: 600px) {
            width: 2%;
          }
        }
      }
    }

    .signInPopupInside {
      padding: 0 20px;
    }
  }

  .steps {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    flex-direction: row;
    text-align: center;

    img {
      // width: 100%;
      height: 40px;
      width: 40px;
      @media only screen and (min-width: 500px) {
        width: 100%;
        height: auto;
      }
    }

    @media only screen and (min-width: 500px) {
      flex-direction: row;
      gap: 90px;
      width: 100%;
    }

    @media only screen and (min-width: 768px) {
      flex-direction: row;
      gap: 130px;
    }

    // .match {
    //   color: #82b5a5;
    // }

    // .chat {
    //   color: #f88561;
    // }

    // .work {
    //   color: #99bbff;
    // }

    p {
      font-family: $primary-font;
      font-style: normal;
      // font-weight: 600;
      font-size: 12px;
      line-height: 36px;
      margin-top: 10px;

      @media only screen and (min-width: 500px) {
        font-size: 24px;
      }
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    padding-top: 40px;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }

    @media only screen and (min-width: 1400px) {
      justify-content: center;
    }

    .line3 {
      position: absolute;
      top: -7%;
      left: 0;
      width: 50%;

      @media only screen and (min-width: 600px) {
        top: -7%;
        left: 0;
        width: 40%;
      }

      @media only screen and (min-width: 768px) {
        width: 30%;
      }

      @media only screen and (min-width: 992px) {
        width: 20%;
      }

      @media only screen and (min-width: 1300px) {
        width: 15%;
      }

      @media only screen and (min-width: 1400px) {
        left: 10%;
        top: -15%;
      }
    }

    .line4 {
      position: absolute;
      bottom: -7%;
      right: 0;
      width: 50%;
      z-index: 2;

      @media only screen and (min-width: 600px) {
        bottom: -7%;
        right: 0;
        width: 40%;
      }

      @media only screen and (min-width: 768px) {
        width: 30%;
      }

      @media only screen and (min-width: 992px) {
        width: 20%;
      }

      @media only screen and (min-width: 1300px) {
        width: 15%;
      }

      @media only screen and (min-width: 1400px) {
        right: 15%;
        bottom: -10%;
      }

      @media only screen and (min-width: 1600px) {
        right: 20%;
      }
    }

    .jobSeeker,
    .Employer {
      background: #fff;
      border: 1px solid #e8e8ea;
      box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
      border-radius: 10px;
      padding: 30px 20px;
      z-index: 1;

      @media only screen and (min-width: 1400px) {
        width: 50%;
        padding: 30px;
      }

      @media only screen and (min-width: 1600px) {
        width: 30%;
      }

      h3 {
        color: $secondary-color;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;

        @media only screen and (min-width: 500px) {
          font-size: 40px;
          line-height: 60px;
        }

        span {
          color: #494e73;
        }
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: $primary-color;
        min-height: 60px;
        margin-bottom: 40px;
        color: #494e73;

        @media only screen and (max-width: 600px) {
          line-height: 22px;
        }

        a {
          color: #494e73;
        }
      }

      .btns {
        display: flex;
        align-items: center;
        gap: 24px;

        img {
          width: 45%;
          cursor: pointer;

          @media only screen and (min-width: 992px) {
            width: 30%;
          }

          @media only screen and (min-width: 1400px) {
            width: 25%;
          }
        }
      }

      .btnsApply {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media only screen and (min-width: 992px) {
          flex-direction: row;
          gap: 24px;
        }

        .getStarted {
          background: $secondary-color;
          box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
          border-radius: 10px;
          border: 1px solid transparent;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #fff;
          line-height: 22px;
          padding: 10px 0;
          transition: transform 0.5s ease;

          @media only screen and (min-width: 992px) {
            padding: 10px 40px;
            width: 369px;
          }

          &:hover {
            transform: scale(1.1);
            cursor: pointer;
          }
        }

        .learnMore {
          background: #ffffff;
          border: 1px solid rgba(0, 102, 255, 0.05);
          box-shadow: 0px 2px 15px rgba(4, 0, 21, 0.1);
          border-radius: 10px;
          border: 1px solid transparent;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: $secondary-color;
          padding: 10px 0;
          cursor: pointer;

          @media only screen and (min-width: 992px) {
            padding: 10px 40px;
          }

          &:hover {
            background: $secondary-color;
            box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
            color: #fff;
          }
        }
      }
    }
  }
}


.anchorChangeService {
  position: absolute;
  margin-top: -200px;

  @media only screen and (max-width: 992px) {
    margin-top: unset;
    top: 0;
  }
}

.storeButton {
  width: 128px !important;
  margin-right: 20px;
}
