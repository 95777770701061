@import "../style/main.scss";

.signInBtn {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: 1px solid rgba(0, 102, 255, 0.05);
  box-shadow: 0px 2px 15px rgb(4 0 21 / 10%);
  border-radius: 10px;
  padding: 10px 36px;
  color: #0066ff;
  width: -moz-fit-content;
  width: 60px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (min-width: 992px) {
    width: 80px;
  }
}

.signUpBtn {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border: 1px solid rgba(0, 102, 255, 0.05);
  box-shadow: 0px 2px 15px rgb(4 0 21 / 10%);
  border-radius: 10px;
  padding: 10px 36px;
  background-color: $secondary-color;
  color: #fff;
  width: 60px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.5s ease;

  &:hover {
    // background: #ffffff;
    // border: 1px solid rgba(0, 102, 255, 0.05);
    // box-shadow: 0px 2px 15px rgba(4, 0, 21, 0.1);
    // border-radius: 10px;
    // color: $secondary-color;
    transform: scale(1.1);
  }

  @media only screen and (min-width: 992px) {
    width: 80px;
  }
}
