@import "../../style/main.scss";

.createAcc {
  h2 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: $primary-color;
    margin-top: -25px;
    width: 80%;
    margin: auto;
    margin-top: -20px;
    margin-bottom: 20px;

    @media only screen and (min-width: 600px) {
      width: 100%;
    }
  }

  .createAccContent {
    padding: 20px;
    @media only screen and (min-width: 600px) {
      // padding: 40px 100px 0px;
      width: 70%;
      margin: auto;
    }

    form {
      .MuiFormControl-root {
        width: 100%;
        margin-bottom: 30px;
      }

      .passwordRequireds {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $third-color;
        margin-top: -28px;
        margin-bottom: 40px;
        margin-left: 20px;
        width: 80%;
      }

      .passwordField,
      .passwordFieldNew {
        position: relative;

        .passwordEye {
          position: absolute;
          right: 10px;
          top: 15px;
          cursor: pointer;
        }

        #checkbox,
        #checkboxRepeat {
          display: none;
        }

        :checked + label::before {
          content: url("../../images/cross-eye.svg");
          position: absolute;
          right: 10px;
          top: 13px;
          cursor: pointer;
        }
      }
    }

    .termsAndPrivacy {
      p {
        color: #333;
        text-align: center;
        font-size: 12px;
        width: 80%;
        margin: auto;
        margin-top: 10px;
      }

      a {
        font-weight: 600;
        text-decoration: underline;
        color: $primary-color;
        cursor: pointer;
      }
    }

    .signIn {
      p {
        color: #333;
        text-align: center;
        font-size: 12px;

        a {
          color: #000;
        }
      }
    }
  }
}
