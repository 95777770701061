@import "../../../style/main.scss";

.terms {
  padding: 40px 20px;

  @media only screen and (min-width: 992px) {
    padding: 30px 45px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 100px 92px;
    width: 60%;
    margin: auto;
  }

  h1 {
    font-weight: 600;
    color: $secondary-color;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
    color: $primary-color;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    color: $secondary-color;
  }

  .firstParagraph {
    margin-top: 0px;
  }

  p {
    color: $primary-color;

    ul {
      li {
        margin-left: 40px;
      }
    }
  }

  a {
    font-weight: bold;
    color: $secondary-color;
  }
}
