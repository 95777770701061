@import "../../style/main.scss";

.forgotPassword {
  .forgotPasswordContent {
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;

    @media only screen and (min-width: 600px) {
      width: 70%;
      margin: auto;
      padding: 0;
      margin-bottom: 0;
    }

    @media only screen and (min-width: 992px) {
      width: 70%;
    }

    .lock {
      width: 25%;
      margin-top: -60px;
    }

    h2 {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: $primary-color;
      margin-top: -40px;
      margin-bottom: 40px;

      @media only screen and (min-width: 600px) {
        width: 100%;
        margin-top: -20px;
      }
    }

    .enterCodeText {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $primary-color;
      margin-top: 20px;

      @media only screen and (min-width: 768px) {
        width: 80%;
        margin: auto;
        margin-top: 40px;
      }
    }

    .enterCode {
      text-align: left;
      margin: 40px 0 20px;
    }

    .MuiFormControl-root {
      width: 100%;
      margin-bottom: 40px;
    }

    #outlined-error {
      color: #d32f2f;
    }

    .resendCode {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-top: 20px;
      margin-bottom: 40px;

      a {
        color: $secondary-color;
      }
    }
  }
}
