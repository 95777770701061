@import "../../style/main.scss";

.otp {
  .otpContent {
    padding: 0 20px;
    text-align: center;

    @media only screen and (min-width: 600px) {
      width: 70%;
      margin: auto;
    }

    @media only screen and (min-width: 992px) {
      width: 70%;
    }

    .otpImage {
      width: 30%;
      margin-top: -20px;
    }

    h2 {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: $primary-color;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    .enterCodeText {
      color: $primary-color;
      width: 80%;
      margin: 40px auto;
    }

    .enterCode {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin: 40px 0 20px;

      @media only screen and (min-width: 992px) {
        gap: 20px;
      }

      input {
        background: #fafafa;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        width: 50px;
        height: 57px;
        outline: none;
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 57px;
        text-align: center;
        color: $primary-color;

        @media only screen and (min-width: 992px) {
          font-size: 54px;
          width: 66px;
          height: 81px;
        }
      }
    }

    .errorMessage {
      font-size: 0.75rem;
      color: #d32f2f;
      margin-bottom: 20px;
    }

    pre {
      display: none;
    }

    .resetInfo {
      font-family: $primary-font;
      font-size: 16px;
      color: $third-color;
      margin-top: 20px;

    }

    .resendCode {
      // margin: 20px;
      margin-bottom: 40px;



      button {
        background-color: transparent;
        border: none;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        color: $secondary-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .signIn {
      p {
        color: #333;
        text-align: center;
        font-size: 12px;
        margin: 30px 0 20px;

        a {
          color: #000;
        }
      }
    }
  }
}
