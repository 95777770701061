@import "../../style/main.scss";

.location {
  h2 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: $primary-color;
    margin-top: -25px;
    width: 80%;
    margin: auto;
    margin-top: -20px;
    margin-bottom: 20px;

    @media only screen and (min-width: 600px) {
      width: 100%;
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 30px;
  }

  button {
    margin-top: 10px;
  }

  .locationContent {
    padding: 20px;

    @media only screen and (min-width: 600px) {
      width: 70%;
      margin: auto;
    }

    p {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $primary-color;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    .locationDescription {
      color: #333;
      text-align: center;
      font-size: 12px;
      margin-top: -10px;
    }

    .MuiAutocomplete-root {
      width: 100%;
    }

    .MuiAutocomplete-inputRoot {
      #combo-box-demo {
        padding: 3px 0px 3px 10px;
      }

      button {
        margin: 0;
      }
    }

    .pin {
      width: 100%;
    }

    form {
      .locationContryCity {
        @media only screen and (min-width: 600px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 30px;

          label {
            width: 50%;
          }
        }
      }
    }

    .locationInfo {
      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $third-color;
        margin-top: 10px;
      }
    }
  }
}
