.matchedContainer {
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  position: relative;

}

.matchedTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20vh;
}

.matchedImagesContainer {
  position: relative;
  margin-top: 35px;
  height: 140px;

}

.matchedImage {
  width: 140px;
  height: 140px;
  object-fit: cover;
  position: absolute;
  left: 45px;
  transition: left 0.4s ease-in-out;

  @media only screen and (max-width: 992px) {
    left: 5px;
  }
}

.matchedImageSecond {
  width: 140px;
  height: 140px;
  object-fit: cover;
  position: absolute;
  right: 45px;
  transition: right 0.4s ease-in-out;

  @media only screen and (max-width: 992px) {
    right: 5px;
  }

}

.matchedSendMessage {
  background: #0066FF;
  box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
  border-radius: 10px;
  width: 300px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: white;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.matchedKeepSwiping {
  background: white;
  box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
  border-radius: 10px;
  width: 300px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0066FF;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.matchedSaveProfile {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #0066FF;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 40px;
}