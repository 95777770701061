@import "../../style/main.scss";

.joinUs {
  h2 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    color: $primary-color;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .popupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }
  .emailPopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 100%;
    height: auto;
    z-index: 4;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      top: 0;
    }

    .emailHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $third-color;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }
    }
  }

  .joinUsInside {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media only screen and (min-width: 600px) {
      // padding: 40px 100px 0px;
      width: 70%;
      margin: auto;
    }

    @media only screen and (min-width: 768px) {
      flex-direction: column-reverse;
    }

    @media only screen and (min-width: 992px) {
    }

    .businessInside {
      background: #ffffff;
      border: 1px solid #e8e8ea;
      box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
      border-radius: 10px;
      position: relative;

      @media only screen and (min-width: 768px) {
      }

      .businessContent {
        padding: 20px;

        .title {
          font-weight: 600;
          font-size: 24px;
          line-height: 34px;
          text-align: left;
          margin-bottom: 10px;
          margin-top: 0;
        }

        p {
          color: $third-color;
        }

        .icons {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          img {
            width: 100%;
          }

          @media only screen and (min-width: 768px) {
            flex-direction: row;
          }

          .appMobile,
          .googleMobile {
            @media only screen and (min-width: 768px) {
              display: none;
            }
          }

          .appDesktop,
          .googleDesktop {
            display: none;
            @media only screen and (min-width: 768px) {
              display: block;
            }
          }
        }
      }
    }

    .or {
      font-style: normal;
      font-weight: 400;
      text-align: center;
      color: #666666;
      margin: 20px 0;

      &::before {
        background-color: rgba(102, 102, 102, 0.25);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 40%;
        right: 0.5em;
        margin-left: -50%;

        @media only screen and (min-width: 768px) {
          width: 35%;
        }
      }

      &::after {
        background-color: rgba(102, 102, 102, 0.25);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 40%;
        left: 0.5em;
        margin-right: -50%;

        @media only screen and (min-width: 768px) {
          width: 35%;
        }
      }
    }
  }
}
