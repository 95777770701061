@import "../../../../style/main.scss";

.edtiProfile {

  @media only screen and (max-width: 992px) {
    padding: 40px 20px 40px 20px !important;

  }

  @media only screen and (min-width: 992px) {
    padding-top: 40px;

  }

  .MuiFormControl-root {
    width: 100%;

    @media only screen and (min-width: 500px) {
      width: 390px;
      display: flex;

    }
    @media only screen and (max-width: 992px) {
      width: 100%;
    }

    label {
      top: -5px;
    }

    input {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #040015;
      padding: 10px 16px;
    }
  }

  .companyURL {
    margin-top: 30px;
  }

  .updateBtn {
    background: $secondary-color;
    box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
    border-radius: 10px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    cursor: pointer;
    padding: 10px 16px;
    margin-top: 30px;
  }

  .uploadLogo {

    .logom {
      width: 100px;
      height: 100px;
      border-radius: 10px;
    }

    p {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $third-color;
      margin-bottom: 4px;
    }

    .image-upload {
      position: relative;
      width: 100px;
      height: 100px;
      background: url("../../../../images//upload-image.svg");
      background-size: cover;
      background-repeat: no-repeat;
      // background: rgb(48, 47, 76);
      border-radius: 10px;

      .logo {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
      }

      label {
        position: absolute;
        width: 26px;
        height: 26px;
        right: -11px;
        bottom: -7px;
        background: #ffffff;
        box-shadow: 0px 1px 10px rgba(4, 0, 21, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .submitImage {
        position: absolute;
        width: 26px;
        height: 26px;
        left: -11px;
        bottom: -7px;
        background: #ffffff;
        box-shadow: 0px 1px 10px rgb(4 0 21 / 10%);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        z-index: 1;
      }

      .uploadedImage {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
      }

      .removeImage {
        position: absolute;
        width: 26px;
        height: 26px;
        right: -11px;
        bottom: -7px;
        background: #ffffff;
        box-shadow: 0px 1px 10px rgba(4, 0, 21, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
      }
    }

    .image-upload > input {
      display: none;
    }
  }

  .UploadOtherImages {

    .uploadOther {
      display: flex;
      gap: 20px;
    }

    .submitImage {
      position: absolute;
      width: 26px;
      height: 26px;
      left: -11px;
      bottom: -7px;
      background: #ffffff;
      box-shadow: 0px 1px 10px rgb(4 0 21 / 10%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      z-index: 1;
    }

    .imagesUpload {
      position: relative;
      width: 52px;
      height: 52px;
      background: url("../../../../images//upload-image.svg");
      background-size: cover;
      background-repeat: no-repeat;
      // background: rgb(48, 47, 76);
      border-radius: 10px;

      .imagesList {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 70px;

        img {
          width: 52px;
          height: 52px;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .arrayOfImages {
        display: flex;
        flex-direction: row;
      }

      .logo {
        width: 52px;
        height: 52px;
        object-fit: cover;
        border-radius: 10px;
      }

      .uploadedImage {
        width: 52px;
        height: 52px;
        object-fit: cover;
        border-radius: 10px;
      }

      label {
        position: absolute;
        width: 26px;
        height: 26px;
        right: -11px;
        bottom: -7px;
        background: #ffffff;
        box-shadow: 0px 1px 10px rgba(4, 0, 21, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .uploadedImages {
        width: 52px;
        height: 52px;
        object-fit: cover;
        border-radius: 10px;
      }

      .listedImagesUploaded {
        display: flex;
        flex-direction: row;
        gap: 20px;

        img {
          width: 52px;
          height: 52px;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }

    p {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $third-color;
      margin-bottom: 4px;
    }

    .imagesUpload > input {
      display: none;
    }
  }

  .MuiAutocomplete-root {
    width: 100%;
  }

  .ck-editor {
    width: 100%;

    @media only screen and (min-width: 500px) {
      width: 390px;
    }

    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }

  // .MuiFormControl-root {
  //   width: 100%;
  // }

  .showImages {
    display: flex;
    flex-direction: row;
    gap: 20px;

    img {
      width: 52px;
      height: 52px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .search {
    margin-bottom: 30px;
    width: 100%;
  }

  .MuiAutocomplete-inputRoot {
    #combo-box-demo {
      padding: 3px 0px 3px 10px;
    }
  }

  .draft-container {
    margin-bottom: 50px;
    border: 1px solid #b0afb5;
    border-radius: 4px;
    padding: 16px;

    .draft-toolbar {
      margin-bottom: 20px;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.error {
  color: #d32f2f;
  height: 30px;
  padding: 5px 0;
  font-size: 12px;
  margin-left: 10px;
}

.loadingContainer {
  transform: rotate(180deg);
  margin-top: 4px;
}

.uploadOtherImage {
  position: relative;
  label {
    position: absolute;
    width: 26px;
    height: 26px;
    right: -11px;
    bottom: -7px;
    background: #ffffff;
    box-shadow: 0px 1px 10px rgba(4, 0, 21, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.for-delete-button {
  position: absolute;
  width: 26px;
  height: 26px;
  left: -5px;
  bottom: -7px;
  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(4, 0, 21, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 12px !important;
    height: 12px !important;
    object-fit: unset !important;
    border-radius: unset !important;
  }
}

.labelImage {
  width: unset !important;
  height: unset !important;
  object-fit: unset !important;
  border-radius: unset !important;
}