@import "./style/main.scss";

.jobPayment {
  button {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    width: 100%;
    background: #0066ff;
    box-shadow: 0px 2px 20px rgb(0 102 255 / 10%);
    border-radius: 10px;
    border: none;
    height: 42px;
    cursor: pointer;
    margin: 20px 0 10px;
  }

  .info {
    text-align: center;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #7f7d87;
  }

  .successJob {
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 34px;
      color: #040015;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7f7d87;
    }
  }
}
