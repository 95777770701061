@import "../style/main.scss";

.search {
  position: absolute;
  width: 232px;
  top: 40px;
  right: 100px;

  @media only screen and (min-width: 768px) {
    width: 40%;
  }

  @media only screen and (min-width: 992px) {
    width: 25%;
  }

  @media only screen and (min-width: 1400px) {
    width: 20%;
  }

  @media only screen and (max-width: 992px) {
    width: unset;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    margin-top: 20px;
    margin-left: 20px;
  }

  input {
    width: 95%;
    padding: 13px 10px;
    border: 1px solid gray;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 0px 4px 84px rgb(0 0 0 / 7%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    outline: none;

    @media only screen and (max-width: 992px) {
      width: calc(100vw - 62px);
    }
  }

  span {
    position: absolute;
    left: 10px;
    top: 30%;
  }
}
