.mainDiv {
  position: relative;
}

.matchedPopup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fafafa;
  border: 1px solid #e8e8ea;
  box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
  transform: translate(0) !important;
  border-radius: 10px;
  width: 500px;
  height: 700px;
  z-index: 4;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    height: 100%;
    width: 100%;
  }

}

