@import "../../../style/main.scss";

.contact {
  position: relative;
  background: linear-gradient(180deg, #ffffff 0%, #eaf3fd 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 20px;

  .popupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }
  .emailPopup {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    bottom: 0;
    margin: auto;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
    transform: translate(0) !important;
    border-radius: 10px;
    width: 350px;
    height: 543px;
    z-index: 4;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      top: 0;
    }

    .emailHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;

      h3 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
        margin-top: 40px;
        margin-bottom: 10px;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
        }
      }

      p {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $third-color;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 55%;
        }
      }
    }
  }

  @media only screen and (min-width: 600px) {
    margin-top: 70px;
  }

  @media only screen and (min-width: 768px) {
    padding: 100px 45px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 100px 92px;
  }

  @media only screen and (min-width: 1700px) {
    padding: 100px 0;
  }

  .wrapper {
    text-align: center;
    @media only screen and (min-width: 1700px) {
      width: 60%;
      margin: auto;
    }

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #252525;
      line-height: 170%;
      text-align: left;

      &.second {
        margin-bottom: 25px;
      }

      @media only screen and (min-width: 768px) {
        font-size: 30px;
      }
    }

    .mobileUp {
      margin-bottom: 20px;
      @media only screen and (min-width: 768px) {
        display: none;
      }
    }

    .notificationBackground {
      background: #31313161;
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 3;
      cursor: pointer;
    }

    .notificationContent {
      position: absolute;
      background: linear-gradient(180deg, #ffffff 36.46%, #f4f9ff 100%);
      border-radius: 24px;
      width: 90%;
      z-index: 4;
      top: 0;

      @media only screen and (min-width: 992px) {
        width: 60%;
      }

      @media only screen and (min-width: 992px) {
        width: 50%;
      }

      @media only screen and (min-width: 1400px) {
        width: 40%;
      }

      .notificationHeader {
        padding: 20px;

        .closePopup {
          text-align: right;
          cursor: pointer;
        }
      }

      .notificationInside {
        padding: 20px;

        h1 {
          font-size: 25px;
          line-height: normal;
          color: $primary-color;
        }

        h4 {
          color: rgba(0, 0, 0, 0.54);
          line-height: normal;
          font-weight: 500;
          margin-top: 20px;
          margin-bottom: 30px;
        }

        p {
          font-size: 14px;
        }

        span {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: rgba(0, 0, 0, 0.54);
        }

        .notifyed {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          margin-top: 20px;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }

    h4 {
      width: 90%;
      font-size: 25px;
      justify-content: center;
      z-index: 1;
    }

    @media only screen and (min-width: 768px) {
      h4 {
        font-size: 30px;
        z-index: 1;
        width: auto;
      }
    }

    @media only screen and (min-width: 992px) {
      h4 {
        font-size: 35px;
      }
    }

    .notifyMe {
      form {
        display: flex;
        align-items: baseline;
        flex-direction: row;
        justify-content: baseline;
        z-index: 1;
        width: 100%;
        gap: 20px;

        input {
          width: 200px;
          height: 40px; // border-radius: 5px;
          // background: #ffffff;
          // border: 1px solid #b0afb5;
          // border-radius: 4px;
          outline: none;
          border: none;
          background: #ffffff;
          box-shadow: 0px 2.24219px 47.0859px rgba(0, 0, 0, 0.07);
          border-radius: 5.60547px;
          padding-left: 15px;
          display: flex;
          align-items: center;
          z-index: 1;

          &::placeholder {
            // padding-left: 15px;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            color: $third-color;
            font-size: 10px;
            @media only screen and (min-width: 768px) {
              font-size: 16px;
            }
          }

          @media only screen and (min-width: 768px) {
            width: 400px;
            height: 67px;
          }
        }

        button {
          // padding: 10px 50px;
          width: 90px;
          height: 40px;
          background: $secondary-color;
          box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
          border-radius: 10px;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 8px;
          color: #ffffff;
          border: none;
          cursor: pointer;
          z-index: 1;
          transition: transform 0.5s ease;

          &:hover {
            transform: scale(1.1);
          }

          @media only screen and (min-width: 768px) {
            font-size: 16px;
            width: 173px;
            height: 67px;
          }
        }
        @media only screen and (min-width: 768px) {
          flex-direction: row;
          gap: 24px;
        }
      }
    }

    .notificationImage {
      // position: absolute;
      // width: 45%;
      // bottom: -35px;
      // right: 0;
      display: none;

      // @media only screen and (min-width: 500px) {
      //   bottom: -45px;
      // }

      // @media only screen and (min-width: 500px) {
      //   bottom: -55px;
      // }

      @media only screen and (min-width: 768px) {
        display: block;
        position: absolute;
        bottom: 46px;
        right: 100px;
        width: 300px;
      }

      // @media only screen and (min-width: 992px) {
      //   width: 35%;
      //   bottom: -65px;
      // }

      // @media only screen and (min-width: 1100px) {
      //   width: 35%;
      //   bottom: -75px;
      // }

      // @media only screen and (min-width: 1200px) {
      //   width: 25%;
      //   bottom: -60px;
      // }

      // @media only screen and (min-width: 1400px) {
      //   width: 25%;
      //   bottom: -70px;
      // }

      // @media only screen and (min-width: 1500px) {
      //   width: 25%;
      //   bottom: -75px;
      // }
      @media only screen and (min-width: 1600px) {
        right: 292px;
      }
      // @media only screen and (min-width: 1700px) {
      //   width: 25%;
      //   bottom: -85px;
      // }
      // @media only screen and (min-width: 1800px) {
      //   width: 25%;
      //   bottom: -90px;
      // }
      @media only screen and (min-width: 1900px) {
        right: 350px;
      }
    }
  }
}
