@import "../../../style/main.scss";

.aboutUs {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  position: relative;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (min-width: 1400px) {
    margin: auto;
  }

  @media only screen and (min-width: 1600px) {
    width: 60%;
    gap: 100px;
  }

  .quote {
    display: none;
    @media only screen and (min-width: 768px) {
      width: 50%;
      display: block;
    }

    @media only screen and (min-width: 1600px) {
      width: 40%;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      color: $primary-color;

      @media only screen and (max-width: 600px) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .quoteImage1 {
      margin-bottom: 24px;
    }

    .quoteImage2 {
      float: right;
      margin-top: 24px;

      @media only screen and (min-width: 768px) {
        width: 5%;
      }
    }
  }

  .story {
    @media only screen and (min-width: 768px) {
      width: 50%;
    }

    .quote_mobile {
      margin-bottom: 80px;
      @media only screen and (min-width: 768px) {
        width: 50%;
        display: none;
      }

      @media only screen and (min-width: 1600px) {
        width: 40%;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;

        @media only screen and (max-width: 600px) {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .quoteImage1 {
        margin-bottom: 24px;
      }

      .quoteImage2 {
        float: right;
        margin-top: 24px;

        @media only screen and (min-width: 768px) {
          width: 5%;
        }
      }
    }

    h1 {
      color: $primary-color;
      text-align: center;
      font-weight: 600;

      span {
        color: $secondary-color;
      }
    }

    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
      text-align: justify;
      margin-top: 20px;

      @media only screen and (max-width: 600px) {
        font-size: 18px;
        line-height: 22px;
      }

      a {
        color: $secondary-color;
      }
    }
  }
}

.anchorChange {
  position: absolute;
  margin-top: -1022px;

  @media only screen and (max-width: 992px) {
    margin-top: unset;
    top: -100px;
  }
}
