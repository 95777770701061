@import "../../../style/main.scss";

.baner {
  background: linear-gradient(180deg, #eaf3fd 0%, #ffffff 100%);
  padding: 40px 20px;

  @media only screen and (min-width: 992px) {
    padding: 30px 45px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 100px 92px;
  }

  @media only screen and (min-width: 1600px) {
    display: flex;
  }

  .signInPopupBackground {
    background: rgba(176, 175, 181, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .signInPopupContent {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 20%;
    left: 0;
    right: 0;
    background: #fafafa;
    border: 1px solid #e8e8ea;
    box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
    border-radius: 10px;
    width: 90%;
    z-index: 4;

    @media only screen and (min-width: 768px) {
      width: 550px;
      height: 740px;
      overflow-y: scroll;
    }

    .signInPopupHeader {
      padding: 40px 20px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
      }

      .closePopup {
        text-align: right;
        cursor: pointer;
        width: 100%;

        img {
          width: 5%;

          @media only screen and (min-width: 768px) {
            width: 2%;
          }
        }
      }
    }

    .signUpPopupHeader {
      padding: 40px 20px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .closePopup {
        text-align: right;
        cursor: pointer;
        width: 100%;

        img {
          width: 5%;

          @media only screen and (min-width: 600px) {
            width: 2%;
          }
        }
      }
    }

    .signInPopupInside {
      padding: 0 20px;
    }
  }

  .banerContent {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media only screen and (min-width: 992px) {
      flex-direction: row;
      align-items: center;
    }

    @media only screen and (min-width: 1600px) {
      width: 65%;
      margin: auto;
    }

    .leftContent {
      position: relative;
      z-index: 1;
      text-align: center;
      @media only screen and (min-width: 992px) {
        width: 50%;
        text-align: left;
      }

      @media only screen and (min-width: 1600px) {
        width: 100%;
      }

      h1 {
        font-weight: 600;
        line-height: 57px;
        color: $secondary-color;
        margin-bottom: 20px;

        @media only screen and (min-width: 768px) {
          width: 500px;
          line-height: 81px;
          margin: auto;
        }

        @media only screen and (min-width: 992px) {
          margin: 0;
        }
      }

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;
      }

      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 34px;
        color: $primary-color;

        @media only screen and (max-width: 600px) {
          font-size: 18px;
          line-height: 22px;
        }

        @media only screen and (min-width: 992px) {
          // width: auto;
          width: 520px;
        }

        @media only screen and (min-width: 1199px) {
          // width: auto;
        }

        @media only screen and (min-width: 1400px) {
          // width: 520px;
        }
      }

      .btns {
        display: flex;
        margin-top: 40px;
        gap: 20px;
        justify-content: center;

        @media only screen and (min-width: 768px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
        }

        @media only screen and (min-width: 992px) {
          flex-direction: row;
        }

        .getStarted {
          width: 271px;
          background: $secondary-color;
          box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
          border-radius: 10px;
          border: 1px solid transparent;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #fff;
          padding: 10px 0;
          cursor: pointer;
          transition: transform 0.5s ease;

          @media only screen and (min-width: 768px) {
            width: 400px;
          }

          @media only screen and (min-width: 992px) {
            // width: 180px;
          }

          &:hover {
            transform: scale(1.1);
          }
        }

        .learnMore {
          width: 50%;
          background: #ffffff;
          border: 1px solid rgba(0, 102, 255, 0.05);
          box-shadow: 0px 2px 15px rgba(4, 0, 21, 0.1);
          border-radius: 10px;
          border: 1px solid transparent;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          text-decoration: none;
          line-height: 22px;
          color: $secondary-color;
          padding: 10px 0;
          cursor: pointer;
          z-index: 2;

          @media only screen and (min-width: 768px) {
            width: 25%;
          }

          @media only screen and (min-width: 992px) {
            width: 180px;
          }

          &:hover {
            background: $secondary-color;
            box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
            border-radius: 10px;
            color: #fff;
          }
        }
      }

      .line1 {
        display: none;

        @media only screen and (min-width: 992px) {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          margin-top: 30px;
          z-index: 1;
        }

        @media only screen and (min-width: 1300px) {
          left: 15%;
          width: 30%;
          margin-top: 15%;
        }

        @media only screen and (min-width: 1500px) {
          left: 10%;
          margin-top: 12%;
          width: 25%;
        }
      }

      .mobileBanerImage {
        width: 100%;
        margin-top: 40px;

        @media only screen and (min-width: 768px) {
          display: none;
        }
      }
    }

    .rightContent {
      position: absolute;
      right: 0;
      bottom: -5%;
      z-index: 0;
      display: none;

      @media only screen and (min-width: 376px) {
        bottom: 15%;
      }

      @media only screen and (min-width: 400px) {
        bottom: 20%;
      }

      @media only screen and (min-width: 992px) {
        position: relative;
        display: block;
      }

      .banerFirstImage {
        display: none;

        @media only screen and (min-width: 992px) {
          display: block;
          width: 80%;
          margin: auto;
        }

        @media only screen and (min-width: 1400px) {
          width: 70%;
        }

        @media only screen and (min-width: 1600px) {
          width: 100%;
        }
      }

      .banerSecondImage {
        @media only screen and (min-width: 992px) {
          display: none;
        }
      }

      .line2 {
        display: none;

        @media only screen and (min-width: 992px) {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 50%;
          margin-right: auto;
          margin-left: auto;
        }

        @media only screen and (min-width: 1500px) {
          width: 30%;
        }
      }

      @media only screen and (min-width: 992px) {
        width: 50%;
        text-align: center;
      }
    }
  }
}
