@import "./variables.scss";

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 75px;

  @media only screen and (min-width: 768px) {
    font-size: 54px;
    line-height: 81px;
  }
}

h2 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: $primary-color;
}

h3 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

h4 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $primary-color;
}

h5 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $primary-color;
}

h6 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $primary-color;
}

p {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.pagePadding {
  padding: 40px 20px 40px 20px;
  width: calc(100vw - 340px);
  position: relative;

  @media only screen and (min-width: 600px) {
    padding: 40px 20px 0px 20px;
  }

  @media only screen and (max-width: 992px) {
    width: 100vw;
    padding: 0;

  }
}

.customPadding {
  padding: 20px 20px;

  @media only screen and (min-width: 768px) {
    padding: 30px 45px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 30px 92px;
  }
}

.MuiFormControl-root {
  .MuiFormLabel-root {
    top: -4px;
  }

  input {
    padding: 10px 14px;
  }
}
