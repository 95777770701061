@import "../../style/main.scss";

.payment {
  .paymentContent {
    padding: 0 20px;
    text-align: center;

    @media only screen and (min-width: 600px) {
      width: 70%;
      margin: auto;
    }

    @media only screen and (min-width: 992px) {
      width: 70%;
    }

    h2 {
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: $primary-color;
      margin-top: -20px;
      margin-bottom: 20px;
    }

    button {
      font-family: Poppins, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: rgb(255, 255, 255);
      line-height: 24px;
      background: rgb(0, 102, 255);
      border: 2px solid rgb(0, 102, 255);
      border-radius: 10px;
      width: 100%;
      padding: 10px 0px;
      cursor: pointer;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .info {
      display: block;
      color: #333;
      text-align: center;
      font-size: 12px;
      width: 80%;
      margin: auto;
      margin-top: 10px;
    }

    .paymentType {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;

      .radioPayment {
        background: #ffffff;
        border: 1px solid #e8e8ea;
        box-shadow: 0px 2px 30px rgba(4, 0, 21, 0.1);
        border-radius: 10px;
        width: 160px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0;

        label {
          display: flex;
          gap: 10px;
          cursor: pointer;

          .radioDesign {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            h3 {
              font-family: $primary-font;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-transform: capitalize;
              color: #7f7d87;
            }

            span {
              font-family: $primary-font;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: $primary-color;
            }
          }
        }
      }
    }

    // .popupBackground {
    //   background: rgba(176, 175, 181, 0.8);
    //   position: fixed;
    //   left: 0;
    //   top: 0;
    //   height: 100%;
    //   width: 100%;
    //   z-index: 3;
    //   cursor: pointer;
    // }

    .emailPopup {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: #fafafa;
      border: 1px solid #e8e8ea;
      box-shadow: 0px 2px 30px rgb(4 0 21 / 10%);
      transform: translate(0) !important;
      border-radius: 10px;
      width: 100%;
      height: 740px;
      z-index: 4;
      overflow: hidden;

      .emailHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;

        h3 {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 34px;
          color: $primary-color;
          margin-top: 40px;
          margin-bottom: 10px;
          width: 80%;

          @media only screen and (min-width: 768px) {
            width: 100%;
          }
        }

        p {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $third-color;
          width: 80%;

          @media only screen and (min-width: 768px) {
            width: 55%;
          }
        }
      }
    }
  }
}
