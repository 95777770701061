@import "./style/main.scss";

* {
  padding: 0;
  margin: 0;
}

.jobPayment {
  .spiner {
    display: flex;
    align-items: center;
    justify-content: center;

    .popupBackground {
      background: rgba(176, 175, 181, 0.8);
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 3;
      cursor: pointer;
    }

    .emailPopup {
      opacity: 1;
      transform: translateX(0%);
      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
      width: 300px;
      z-index: 4;
      cursor: pointer;
      background: #fff;
      border: 1px solid #e8e8ea;
      box-shadow: -2px 0px 30px rgb(4 0 21 / 10%);

      overflow-y: scroll;

      @media only screen and (min-width: 768px) {
        width: 350px;
      }

      .FilterPopupHeader {
        h3 {
          color: $primary-color;
          font-weight: 600;
          padding: 20px 20px 0;
          img {
            margin-right: 10px;
          }
        }
        .closePopup {
          position: absolute;
          top: 20px;
          right: 20px;
        }
      }
    }

    .filterPopupInside {
      margin-top: 20px;
      border-top: 1px solid rgba(127, 125, 135, 0.5);
      padding: 20px;
      overflow-y: scroll;
    }
  }
}
