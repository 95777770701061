@import "../../../../../style/main.scss";

.MuiTabs-root {
  .MuiTabs-scrollButtons {
    display: none;
  }
  .MuiTabs-flexContainer {
    // margin-top: 40px;
    display: flex;
    gap: 15px;

    button {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $primary-color;
      min-width: auto;
      padding: 0;
    }
  }

  .MuiTabs-indicator {
    background-color: $primary-color;
    height: 1px;
    bottom: 10px;
  }
}
