@import "../../../../style/main.scss";


.matchContent {
  // padding: 20px;

  @media only screen and (min-width: 768px) {
    display: flex;
  }

  @media only screen and (min-width: 992px) {
    width: 100%;
    padding-top: 40px;
  }

  @media only screen and (min-width: 1400px) {
    width: 70%;
  }

  .list {
    list-style-type: none;
    margin-top: 20px;

    @media only screen and (min-width: 768px) {
      width: calc(100vw - 300px);

      margin-top: 0;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;

      &:nth-child(1) {
        margin-top: 0;
      }

      .matchInfo {
        display: flex;
        align-items: center;
        gap: 10px;

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: normal;
          margin: 0;
        }

      }

     .matchPercentages {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #0066ff;
        font-style: normal;
        font-weight: 600;
        background: rgb(0 102 255 / 20%);
        border-radius: 4px;
        text-transform: uppercase;
        padding-left: 4px;
        padding-right: 4px;
        width: fit-content;
        margin-bottom: 4px;
       height: 22px;
       display: flex;
       align-items: center;
       margin-right: 6px;
      }

      .matchFunctions {
        display: flex;
        align-items: baseline;
        gap: 5px;

        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .Mui-checked {
          color: $third-color;
        }
      }
    }
  }
}

.matchUser {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.matchInfo {
  cursor: pointer;
  width: 100%;
}

.list {
  height: calc(100vh - 40px);
  overflow: scroll;

  @media only screen and (max-width: 992px) {
    height: calc(100vh - 168px);
    padding-left: 20px;
    padding-right: 20px;
  }
}

.listItem {
  width: 500px;


  @media only screen and (max-width: 992px) {
    width: 100%
  }


}

.matchUserName {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 2px;

  @media only screen and (max-width: 992px) {
    font-size: 16px;
  }
}

  .match {
    background: #e1ebfb;
    border-radius: 4px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: $secondary-color;
    padding: 4px 6px;
    text-transform: uppercase;
  }

  .jobSeeking {
    background: #c4f1d9;
    border-radius: 4px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #14c364;
    text-transform: uppercase;
    padding: 0px 6px;
    width: -moz-fit-content;
    width: fit-content;
    height: 22px;

  }

  .openToWork {
    color: #CE8D0F;
    background: #FFEEC0;
    border-radius: 4px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0px 6px;
    width: -moz-fit-content;
    width: fit-content;
    height: 22px;

  }

  .networking {
    color: #10C9E2;
    background: #D7F3F6;
    border-radius: 4px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0px 6px;
    width: -moz-fit-content;
    width: fit-content;
    height: 22px;
  }

  .pillsContainer {
    display: flex;
  }