@import "../../../style/main.scss";

.navigationMobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e8e8ea;
  box-shadow: 0px -2px 30px rgba(4, 0, 21, 0.1);
  width: calc(100vw - 42px);

  .hamburger {
    color: #fff;
  }

  .logo {
    width: 30%;
  }

  .menuBackground {
    background: rgba(4, 1, 16, 0.85);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    cursor: pointer;
  }

  .mobileContentMenu {
    position: fixed;
    right: 0;
    top: 0;
    background: #ffffff;
    border: 1px solid #e8e8ea;
    box-shadow: 0px -2px 30px rgba(4, 0, 21, 0.1);
    height: 100%;
    width: 300px;
    border-right: 1px solid $primary-color;
    z-index: 3;
    overflow-y: scroll;

    ul {
      list-style-type: none;
      margin-top: 20px;
      border-top: 1px solid rgba(127, 125, 135, 0.5);
      border-bottom: 1px solid rgba(127, 125, 135, 0.5);
      padding: 20px;

      li {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 12px 0;
        position: relative;
        a {
          font-family: $primary-font;
          font-weight: 400;
          color: $third-color;
          text-decoration: none;
        }
        span {
          background: #0066ff;
          border-radius: 4px;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #fff;
          padding-left: 3px;
          padding-right: 3px;
          position: absolute;
          right: 0;
        }
      }
    }

    .logOut {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 20px;

      a {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: $third-color;
        text-decoration: none;
      }
    }

    .postJob {
      position: absolute;
      bottom: 20px;
      left: 20px;
      width: 260px;
      background: $secondary-color;
      box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
      border-radius: 10px;
      text-align: center;
      padding: 10px 0;
      a {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #fff;
        text-decoration: none;
      }
    }

    .mobileMenuHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 0;

      .mobileMenu {
        h3 {
          font-weight: 600;
          color: $third-color;
        }
      }
    }

    .avatarName {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 30px;

      img {
        width: 42px;
        height: 42px;
        object-fit: cover;
        border-radius: 10px;
      }

      h3 {
        font-weight: 600;
        color: $third-color;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    display: none;
  }
}
