@import "../../../style/main.scss";

.footerFull {
  box-shadow: 0px -2px 30px rgba(4, 0, 21, 0.1);
  .footerCopyright {
    .footer {
      padding: 40px 20px;

      @media only screen and (min-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 92px;
      }

      @media only screen and (min-width: 1600px) {
        width: 60%;
        margin: auto;
      }

      h4 {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #000;
        margin-bottom: 24px;
      }

      ul {
        list-style-type: none;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
          font-family: $primary-font;
          font-weight: 400;
          font-size: 12.0376px;
          line-height: 150%;

          @media only screen and (min-width: 992px) {
            font-size: 16px;
          }

          a {
            color: #000000;
          }
        }
      }

      .about {
        img {
          width: 30%;
          margin-bottom: 15px;

          @media only screen and (min-width: 992px) {
            width: 30%;
          }

          @media only screen and (min-width: 1300px) {
            width: 40%;
          }
        }

        p {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 12.3441px;
          line-height: 19px;
          color: #515151;
          width: 287px;
          margin-bottom: 40px;

          @media only screen and (min-width: 992px) {
            font-size: 16px;
          }
        }
      }

      .product {
        span {
          color: #bbbbbb;
        }
      }

      .contactAndLegacy {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        @media only screen and (min-width: 768px) {
          justify-content: flex-start;
          gap: 60px;
        }

        @media only screen and (min-width: 992px) {
          gap: 150px;
        }

        img {
          width: 12px;
          height: 12px;
          margin-right: 12px;
        }
      }
    }

    .icons {
      margin-bottom: 30px;
      padding: 0px 20px;
      display: flex;
      flex-direction: column;
      gap: 50px;

      @media only screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }

      @media only screen and (min-width: 992px) {
        padding: 0px 92px;
      }

      @media only screen and (min-width: 1600px) {
        width: 60%;
        margin: auto;
        margin-bottom: 30px;
      }

      .phone {
        display: flex;
        align-items: center;
        gap: 24px;

        img {
          width: 100%;
        }
      }

      .social {
        text-align: center;
        display: flex;
        align-items: center;
      }
    }

    .copyright {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: left;
      color: $third-color;
      padding: 20px;
      border-top: 0.8px solid #d2d2d2;
      text-align: center;

      @media only screen and (min-width: 768px) {
        padding: 20px 45px;
      }

      @media only screen and (min-width: 1200px) {
        text-align: center;
      }
    }
  }

  .tikTokIcon {
    background-color: white;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    box-shadow: 0px 5.88235px 20.5882px rgb(0 0 0 / 15%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 9px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .tikTokIconImage {
    width: 48px;
  }
}
