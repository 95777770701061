@import "../../../../style/main.scss";

.saveMatchesContent {
  width: auto;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media only screen and (min-width: 992px) {
    width: 100%;
    padding-top: 40px;
  }

  @media only screen and (min-width: 1400px) {
    width: 70%;
  }

  .saved {
    list-style-type: none;
    margin-top: 20px;
    height: 90vh;
    overflow-y: scroll;

    @media only screen and (min-width: 768px) {
      width: 50%;
      margin-top: 0;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;

      &:nth-child(1) {
        margin-top: 0;
      }

      .matchInfo {
        display: flex;
        align-items: center;
        gap: 10px;

        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: normal;
          margin: 0;
        }

        h6 {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          color: #0066ff;
          font-style: normal;
          font-weight: 600;
          background: rgb(0 102 255 / 20%);
          border-radius: 4px;
          text-transform: uppercase;
          padding: 4px;
          width: fit-content;
          margin-bottom: 4px;
        }
      }

      .matchFunctions {
        display: flex;
        align-items: baseline;
        gap: 5px;

        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
        }

        .Mui-checked {
          color: $third-color;
        }
      }
    }
  }
}
