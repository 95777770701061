@import "../../../../../style/main.scss";

.changePassword {
  h2 {
    color: $third-color;
  }

  .passwordFields {
    margin-top: 20px;

    .form {
      width: 100%;
      margin-bottom: 20px;

      @media only screen and (min-width: 500px) {
        width: 390px;
        display: flex;
      }

      &:nth-child(3) {
        margin-bottom: 40px;
      }

      label {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        top: -5px;

        &.Mui-focused {
          color: $third-color;
        }
      }

      input {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $primary-color;
        padding: 10px 16px;
      }

      fieldset {
        &.MuiOutlinedInput-notchedOutline {
          border-color: #b0afb5;
          border-width: 1px;
        }
      }

      span {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $third-color;
        width: 80%;
        margin-left: 10px;
        margin-top: 4px;
      }
    }

    .passwordField,
    .passwordFieldNew,
    .passwordFieldConfirmed {
      position: relative;

      .passwordEye {
        position: absolute;
        right: 10px;
        top: 15px;
        cursor: pointer;
      }

      #checkbox,
      #checkboxRepeat,
      #checkboxConfirmed {
        display: none;
      }

      :checked + label::before {
        content: url("../../../../../images/cross-eye.svg");
        position: absolute;
        right: 10px;
        top: 13px;
        cursor: pointer;
      }
    }
    .updatePassword {
      background: $secondary-color;
      box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
      border-radius: 10px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      cursor: pointer;
      padding: 10px 16px;
    }
  }
}

.changePasswordReqs {
  color: #7f7d87;
  font-family: Poppins,sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 15px;
  margin-top: -18px;
  width: 350px;
}
