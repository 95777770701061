@import "../../../../style/main.scss";

.avatar-image {
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 10px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.view {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 78px); // for fallback purposes
  height: calc( 100dvh - 78px);

  @media only screen and (min-width: 992px) {
    flex-direction: row;
    width: calc(100vw - 300px);
    height: 100vh;

    // height: 100vh;
    // overflow-y: scroll;
  }

  .dashboard {
    width: 100%;

    @media only screen and (max-width: 768px) {
      padding-top: 20px;
    }

    .noMatches {
      height: 70vh;
      width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .noMoreMatchesIcon {
        width: 158px;
        height: 129px;
        margin-bottom: 40px;
      }

      .noMatchesText {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: #040015;
        margin-bottom: 10px;
      }

      .noMatchesParagraph {
        font-size: 16px;
        text-align: center;
        color: #7F7D87;
        margin-bottom: 40px;
      }

      .reloadBtn {
        width: 344px;
        padding: 10px;
        border-radius: 10px;
        border: 0;
        background-color: #0066FF;
        color: #fff;
        cursor: pointer;
      }
    }

    .title {
      margin: 20px 0 20px 20px;
      font-weight: 600;

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .matches {
      position: relative;
      // padding: 0 10px;
      margin-bottom: 40px;
      margin-right: 100px;

      @media only screen and (max-width: 768px) {
        display: none;
      }

      .react-multi-carousel-list {
        width: calc(100vw - 300px);

        @media only screen and (max-width: 992px) {
          width: unset;
        }

        .react-multiple-carousel__arrow {
          display: none;
        }

        .avatar-icon {
          text-align: center;
          cursor: pointer;

          a {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $primary-color;
            text-decoration: none;
          }
        }
      }

      .shadow {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 80px;
        background: linear-gradient(
                        180deg,
                        rgb(250 250 250 / 52%) 0%,
                        rgba(250, 250, 250, 0.5) 100%
        );
      }
    }

    .filter {
      position: fixed;
      right: 33px;
      top: 70px;
      border-radius: 50%;
      border: 1px solid #e8e8ea;
      box-shadow: 0px -2px 30px rgba(4, 0, 21, 0.1);
      background: #fff;
      padding: 15px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media only screen and (max-width: 992px) {
        padding: 0;
        width: 0;
        height: 0;
      }

      .filterImg {
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }

      .FilterPopupBackground {
        background: rgba(176, 175, 181, 0.8);
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 3;
        cursor: pointer;
      }

      .FilterPopupContent {
        opacity: 1;
        transform: translateX(0%);
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        width: 300px;
        z-index: 4;
        cursor: pointer;
        background: #fff;
        border-left: 1px solid #fff;
        overflow-y: scroll;

        @media only screen and (min-width: 768px) {
          width: 350px;
        }

        .FilterPopupHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 20px;
          margin-right: 20px;
          margin-top: 20px;

          h3 {
            color: $primary-color;
          }

          .closePopup {

          }

          .backPopUp {
            margin-right: 20px;
          }
        }
      }

      .filterPopupInside {
        margin-top: 20px;
        border-top: 1px solid rgba(127, 125, 135, 0.5);
        padding: 20px;

        .MuiTextField-root {
          width: 100%;
          margin-top: 20px;

          label {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            color: $third-color;
            line-height: 18px;
          }

          .MuiOutlinedInput-root {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $primary-color;

            .MuiSelect-select {
              padding: 10px 16px;
            }

            svg {
              color: #fff;
            }

            fieldset {
              border-color: $third-color;
              border-radius: 4px;
            }
          }
        }

        .radius {
          margin: 40px 0;

          h6 {
            color: $third-color;
            margin-bottom: 15px;
          }

          .searchRadiusActivated {
            border-radius: 1px;

            .MuiSlider-rail {
              color: #e2e2e2;
              opacity: 1;
            }

            .MuiSlider-track {
              color: $secondary-color;
              border: none;
            }

            .MuiSlider-valueLabel {
              background-color: transparent;
              font-family: $primary-font;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              top: 0;
              color: $secondary-color;
            }

            .MuiSlider-thumb {
              &::after {
                background: #fff;
                border: 6px solid $secondary-color;
                width: 16px;
                height: 16px;
              }
            }
          }

          .searchRadius {
            border-radius: 1px;

            .MuiSlider-rail {
              color: #e2e2e2;
              opacity: 1;
            }

            .MuiSlider-track {
              color: $primary-color;
              border: none;
            }

            .MuiSlider-valueLabel {
              background-color: transparent;
              font-family: $primary-font;
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              top: 0;
              color: $primary-color;
            }

            .MuiSlider-thumb {
              &::after {
                background: #fff;
                border: 6px solid $primary-color;
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        .tagCategories,
        .levels {
          margin-bottom: 40px;

          h6 {
            color: $third-color;
            margin-bottom: 4px;
          }

          .filterList {
            label {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              border: 1px solid #7f7d87;
              border-radius: 4px;
              padding: 10px 16px;
              margin-bottom: 10px;
              font-family: "Poppins", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #040015;
              cursor: pointer;
            }
          }

          .checkbox {
            .MuiFormGroup-row {
              display: flex;
              flex-direction: column;
              width: 100%;

              label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                border: 1px solid $third-color;
                border-radius: 4px;
                color: #fff;
                padding: 10px 16px;
                margin: 10px 0;

                .MuiCheckbox-root {
                  border: 1px solid $secondary-color;
                  border-radius: 4px;
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }


        .workTypes,
        .workPlaces {
          margin-bottom: 40px;

          h6 {
            color: $third-color;
            margin-bottom: 4px;
          }

          .tags {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 5px;
            margin-bottom: 0;
            flex-direction: row;

            .style {
              input.chk-btn {
                display: none;
              }

              input.chk-btn + label {
                cursor: pointer;
                background: #b0afb5;
                border-radius: 4px;
                font-family: $primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #e8e8ea;
                padding: 4px 6px;
              }

              input.chk-btn:not(:checked) + label:hover {
                background-color: $primary-color;
              }

              input.chk-btn + label:active,
              input.chk-btn:checked + label {
                background: $primary-color;
              }
            }
          }
        }

        .filterButtons {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 20px;
          margin-bottom: 20px;

          .apply {
            background: #0066ff;
            box-shadow: 0px 2px 20px rgba(0, 102, 255, 0.1);
            border-radius: 10px;
            border: none;
            width: 100%;
            padding: 10px 0;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #fff;
            cursor: pointer;
          }

          .clear {
            border: none;
            background-color: transparent;
            font-family: $primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: $third-color;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagStyle {
  margin-right: 5px;
}

.workTypeButton {
  position: relative;
  z-index: 10;
}

.addMatches {
  color: #0066FF;
  cursor: pointer;
  font-size: 12px;
  display: block;

}

.otherTags {
  margin-bottom: 40px;

  h6 {
    color: $third-color;
    margin-bottom: 4px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
    margin-bottom: 0;
    flex-direction: row;
    margin-bottom: 20px;

    .style {
      input.chk-btn {
        display: none;
      }

      input.chk-btn + label {
        cursor: pointer;
        background: #b0afb5;
        border-radius: 4px;
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #e8e8ea;
        padding: 4px 6px;
      }

      input.chk-btn:not(:checked) + label:hover {
        background-color: $primary-color;
      }

      input.chk-btn + label:active,
      input.chk-btn:checked + label {
        background: $primary-color;
      }
    }
  }
}